import React from 'react'
import { Link } from 'react-router-dom'

// Styles
import './RequestLink.css'

/**
 * @param {object} request - The request object.
 * @param {React.ReactNode} children - The content to be displayed inside the link.
 * @param {"primary" | "secondary"} [color="primary"] - The color of the link.
 * @param {string} [className] - Additional class names for the button.
 *
 */

const RequestLink = ({ request, children, color = 'primary', className }) => {
  const path = request.requestType === 'reprint' ? 'reprints' : 'sms-campaigns'
  return (
    <Link className={`request-link ${className ? className : ''} request-link--${color}`} to={`/${path}/${request.id}`}>
      {children}
    </Link>
  )
}

export default RequestLink
