import React, { useState } from 'react'

// Plugins
import { HomeOutlined, CustomerServiceOutlined } from '@ant-design/icons'

// Components
import Page from '../layout/Page'
import PagedList from '../shared/PagedList'
import BaseLayout from '../layout/BaseLayout'
import { buildBreadCrumbs } from '../../utilities/breadcrumbs'

// Helpers / Hooks
import smsCampaignsListProps from '../requests/smsCampaignsListProps'

const Requests = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  }

  const clearSelection = () => setSelectedRowKeys([])

  return (
    <BaseLayout section="sms-campaigns" breadcrumb={breadcrumb}>
      <Page>
        <PagedList {...smsCampaignsListProps} rowSelection={rowSelection} selectedRowKeys={selectedRowKeys} clearSelection={clearSelection} />
      </Page>
    </BaseLayout>
  )
}

const breadcrumb = buildBreadCrumbs([
  {
    href: '/',
    icon: <HomeOutlined />
  },
  {
    icon: <CustomerServiceOutlined />,
    name: 'Support'
  },
  {
    href: '/sms-campaigns',
    name: 'SMS Campaigns'
  }
])

export default Requests
