import React from 'react'
import SelectControl from './controls/Select'
import StaticSelectControl from './controls/StaticSelect'
import RangePickerControl from './controls/RangePicker'

const Controls = ({ tableParams, options, onChange, selectedRowKeys, clearSelection }) => {
  return (
    <span className="flex flex-grow">
      {options.map((option, ind) => {
        if (option.controlType === 'select') {
          return <SelectControl key={ind} control={option} onChange={onChange} />
        } else if (option.controlType === 'static-select') {
          return <StaticSelectControl key={ind} control={option} onChange={onChange} />
        } else if (option.controlType === 'range-picker') {
          return <RangePickerControl key={ind} control={option} onChange={onChange} />
        } else if (option.controlType === 'custom') {
          const props = { tableParams: tableParams, key: ind, selectedRowKeys, clearSelection }
          const component = React.createElement(option.component, props)
          return component
        } else {
          return null
        }
      })}
    </span>
  )
}

export default Controls
