import React from 'react'

// Plugins
import { Dropdown, Tooltip } from 'antd'
import { DownOutlined } from '@ant-design/icons'

// Components
import DrawerLink from '../shared/DrawerLink/DrawerLink'
import PolicyLink from '../policies/PolicyLink'
import PoliciesEditDrawer from './PoliciesEditDrawer'

// Helpers / Hooks
import { hasPermission } from '../../utilities/permissions'

const PolicyActionsDropdown = ({ companyPolicy }) => {
  const canEdit = hasPermission('admin_update_company_policies') || hasPermission('super_admin')
  const items = [
    { key: '0', label: <PolicyLink companyPolicy={companyPolicy}>View</PolicyLink> },
    {
      key: '1',
      label: (
        <DrawerLink drawerElement={PoliciesEditDrawer} childProps={{ companyPolicy: companyPolicy }} disabled={!canEdit}>
          <Tooltip title={canEdit ? null : 'You must request permission to be able to edit a company policy.'}>
            <span>Edit Company Policy</span>
          </Tooltip>
        </DrawerLink>
      )
    }
  ]

  return (
    <Dropdown menu={{ items }} trigger={['click']}>
      <span className="ant-dropdown-link">
        Actions &nbsp;
        <DownOutlined />
      </span>
    </Dropdown>
  )
}

export default PolicyActionsDropdown
