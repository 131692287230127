import React from 'react'
import DrawerLink from '../shared/DrawerLink/DrawerLink'
import JobDrawer from './JobDrawer'

const JobLink = ({ job, disabled = false, children }) => {
  return (
    <DrawerLink drawerElement={JobDrawer} childProps={{ id: job.id }} disabled={disabled}>
      {children || job.name}
    </DrawerLink>
  )
}

export default JobLink
