import React from 'react'
import { Form, Input, Button, message } from 'antd'
import { useMutation } from '@apollo/react-hooks'
import { useGraphqlError, useErrors } from '../../utilities/hooks'
import Drawer from '../shared/Drawer'
import { ZERO_GIFT_CARDS } from '../../graphql/mutations'

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}
const tailLayout = {
  wrapperCol: { offset: 6, span: 18 }
}

const ZeroCreditsDrawer = (props) => {
  const { onClose } = props
  const [zeroGiftCards, { data, loading, error }] = useMutation(ZERO_GIFT_CARDS)
  const { TextArea } = Input

  useErrors(data?.zeroGiftCards?.errors)
  useGraphqlError(error)

  const onFinish = async (values) => {
    const params = { variables: { input: { nums: values.nums } } }
    const response = await zeroGiftCards(params)
    if (response?.data?.zeroGiftCards?.errors?.length === 0) {
      message.success('Credits Updated.')
      onClose()
    }
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  return (
    <Drawer {...props} width={700}>
      <h3>Zero Credits</h3>
      <Form {...layout} className="p-5" name="basic" onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <div>Credit numbers (delimited by comma or new line)</div>
        <Form.Item name="nums" noStyle>
          <TextArea rows={20} />
        </Form.Item>

        <Form.Item {...tailLayout} noStyle>
          <Button className="mt-3" type="primary" htmlType="submit" loading={loading}>
            Process
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  )
}

export default ZeroCreditsDrawer
