import React from 'react'
import { Cascader, Spin } from 'antd'
import { useApolloClient } from '@apollo/react-hooks'
import useListQuery from '../../../api/useListQuery'
import { set, clear } from '../../../utilities/storage'
import { STUDIO_SHORT_LIST_QUERY } from '../../../graphql/queries'

const StudioDropdown = ({ studioId, displayId, displayReportingCode, onChange }) => {
  const client = useApolloClient()
  const { items, loading } = useListQuery('studios', STUDIO_SHORT_LIST_QUERY, { perPage: 99999, order: 'name' })

  const options = items.map((studio) => {
    const studioLabel = displayReportingCode && studio?.reportingCode ? `${studio.name} (${studio.reportingCode})` : studio.name
    return { value: displayId ? `${studio.name} (${studio.id})` : studio.id, label: studioLabel }
  })

  const onStudioSelect = async (studioId) => {
    if (studioId) {
      set('studioId', studioId)
    } else {
      console.log('cleared')
      clear('studioId')
    }

    await client.refetchQueries({ include: 'all' })
  }

  return (
    <Spin spinning={loading}>
      <Cascader placeholder="Select a studio" showSearch options={options} defaultValue={studioId ? [studioId] : []} onChange={onChange ?? onStudioSelect} />
    </Spin>
  )
}

export default StudioDropdown
