import React from 'react'
import Copy from '../shared/Copy'
import { StudioLink, JobLink, CreditLink } from '../shared/Links'
import { dateFormatter, currencyFormatter, phoneFormatter, emailFormatter } from '../../utilities/formatters'
import listProps from '../../utilities/listProps'
import CreditActionsDropdown from './CreditActionsDropdown'
import { GIFT_CARDS_LIST_QUERY } from '../../graphql/queries'
import ZeroButton from './ZeroButton'

const creditListProps = listProps('giftCards', GIFT_CARDS_LIST_QUERY, {
  searchPlaceholder: 'Search credits...',
  controls: [
    {
      controlType: 'custom',
      component: ZeroButton
    }
  ],
  columns: [
    {
      dataIndex: 'num',
      title: 'Number',
      sorter: true,
      render: (num, credit) => (
        <Copy text={num}>
          <CreditLink credit={credit} />
        </Copy>
      )
    },
    {
      dataIndex: 'studio.name',
      title: 'Studio',
      sorter: true,
      render: (_name, giftCard) => <StudioLink studio={giftCard.studio} />
    },
    {
      dataIndex: 'job.name',
      title: 'Job',
      sorter: true,
      render: (name, giftCard) => <JobLink job={giftCard.job}>{name}</JobLink>
    },
    {
      dataIndex: 'phone',
      title: 'Phone',
      sorter: true,
      render: phoneFormatter
    },
    {
      dataIndex: 'email',
      title: 'E-mail',
      sorter: true,
      render: emailFormatter
    },
    {
      dataIndex: 'amountCents',
      title: 'Amount',
      render: currencyFormatter
    },
    {
      dataIndex: 'remainingAmountCents',
      title: 'Remaining Amount',
      render: currencyFormatter
    },
    {
      dataIndex: 'purchasedAt',
      title: 'Purchased At',
      render: dateFormatter
    },
    {
      render: (_, credit) => <CreditActionsDropdown credit={credit} />
    }
  ]
})

export default creditListProps
