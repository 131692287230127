import React, { useState, useEffect } from 'react'
import { Popconfirm, Divider, Typography, Space, Modal, message } from 'antd'
import { isEmpty } from 'lodash'
import { useMutation } from '@apollo/react-hooks'
import { studioLinkFormatter, studioHref } from '../../../utilities/formatters'
import PriceSheetDropdown from './PriceSheetDropdown'
import StudioDropdown from '../../../components/layout/header/StudioDropdown'
import { DUPLICATE_PRICE_SHEET } from '../../../graphql/mutations'
import { useErrors, useGraphqlError } from '../../../utilities/hooks'

const { Text } = Typography

const DuplicatePriceSheetModal = ({ isVisible, onClose, studio }) => {
  const [selectedPriceSheet, setSelectedPriceSheet] = useState(null)
  const [selectedStudio, setSelectedStudio] = useState(null)
  const [isCopyEnabled, setIsCopyEnabled] = useState(false)
  const [popupOpen, setPopupOpen] = useState(false)

  const [duplicatePriceSheet, { data: dataDuplicatePriceSheet, error }] = useMutation(DUPLICATE_PRICE_SHEET, {
    onError: (error) => {
      message.error(`Error while cancelling copying the price sheet: ${JSON.stringify(error)}`)
      console.log(error)
    }
  })

  const handleConfirm = () => {
    setPopupOpen(true)
  }

  const handlePriceSheetChange = (_, selectedPriceSheet) => {
    setSelectedPriceSheet({ id: selectedPriceSheet?.value, name: selectedPriceSheet?.label })
    setIsCopyEnabled(!isEmpty(selectedPriceSheet?.value) && !isEmpty(selectedStudio))
  }

  const handleStudioChange = (studioIds) => {
    setSelectedStudio(studioIds?.[0] ?? null)
    setIsCopyEnabled(!isEmpty(selectedPriceSheet?.id) && !isEmpty(studioIds?.[0]))
  }

  const handlePopupConfirm = async () => {
    const priceSheetId = selectedPriceSheet?.id
    const studioId = selectedStudio.split('(')[1].slice(0, -1)
    const response = await duplicatePriceSheet({ variables: { input: { id: priceSheetId, studioId } } })

    if (response?.data?.duplicatePriceSheet?.errors?.length === 0) {
      const priceSheetId = response.data?.duplicatePriceSheet?.priceSheet?.id
      const url = `/storefront/price-sheets/${priceSheetId}`
      setPopupOpen(false)
      navigator.clipboard.writeText(studioHref(url))
      message.success({
        type: 'success',
        content: (
          <>
            Price sheet copied successfully (link copied to clipboard).
            <br />
            Studio link: {studioHref(url)}
            {studioLinkFormatter(url)}
          </>
        ),
        duration: 10
      })
    }

    onClose()
  }

  const handlePopupCancel = () => {
    setPopupOpen(false)
  }

  useGraphqlError(error)
  useErrors(dataDuplicatePriceSheet?.duplicatePriceSheet?.errors)

  useEffect(() => {
    if (!isVisible) {
      setSelectedPriceSheet(null)
      setSelectedStudio(null)
      setIsCopyEnabled(false)
      setPopupOpen(false)
    }
  }, [isVisible])

  return (
    <Modal
      width={750}
      title="Copy Price Sheet"
      okText="Copy"
      open={isVisible}
      onOk={handleConfirm}
      okButtonProps={{ disabled: !isCopyEnabled || popupOpen }}
      onCancel={onClose}
      cancelButtonProps={{ disabled: popupOpen }}
      destroyOnClose
    >
      <Space>
        <PriceSheetDropdown studio={studio} onChange={handlePriceSheetChange} />
        <StudioDropdown displayId displayReportingCode onChange={handleStudioChange} />
      </Space>
      <Divider dashed plain></Divider>
      <Popconfirm
        title="Are you sure you want to copy this price sheet?"
        open={popupOpen}
        onConfirm={handlePopupConfirm}
        onCancel={handlePopupCancel}
        okText="Yes"
        cancelText="No"
      >
        <Space>
          <Text strong>Selected Price Sheet:</Text>
          <Text type={isEmpty(selectedPriceSheet?.id) ? 'danger' : 'success'}>
            {selectedPriceSheet ? `${selectedPriceSheet?.name} (${selectedPriceSheet?.id})` : 'Not Selected'}
          </Text>
        </Space>
        <Divider dashed plain></Divider>
        <Space>
          <Text strong>Selected Target Studio:</Text>
          <Text type={isEmpty(selectedStudio) ? 'danger' : 'success'}>{selectedStudio ?? 'Not Selected'}</Text>
        </Space>
      </Popconfirm>
    </Modal>
  )
}

export default DuplicatePriceSheetModal
