import React from 'react'
import { HomeOutlined, ProfileOutlined } from '@ant-design/icons'
import BaseLayout from '../layout/BaseLayout'
import Page from '../layout/Page'
import PagedList from '../shared/PagedList'
import customerListProps from '../customers/customerListProps'
import { buildBreadCrumbs } from '../../utilities/breadcrumbs'

const Customers = () => {
  return (
    <BaseLayout section="customers" breadcrumb={breadcrumb}>
      <Page>
        <PagedList {...customerListProps} />
      </Page>
    </BaseLayout>
  )
}

const breadcrumb = buildBreadCrumbs([
  {
    href: '/',
    icon: <HomeOutlined />
  },
  {
    icon: <ProfileOutlined />,
    name: 'Profiles'
  },
  {
    href: '/customers',
    name: 'Customers'
  }
])

export default Customers
