import React from 'react'
import { HomeOutlined } from '@ant-design/icons'
import BaseLayout from '../layout/BaseLayout'
import Page from '../layout/Page'
import PagedList from '../shared/PagedList'
import policiesListProps from '../policies/policiesListProps'
import { buildBreadCrumbs } from '../../utilities/breadcrumbs'

const Policies = () => {
  return (
    <BaseLayout section="policies" breadcrumb={breadcrumb}>
      <Page>
        <PagedList {...policiesListProps} />
      </Page>
    </BaseLayout>
  )
}

const breadcrumb = buildBreadCrumbs([
  {
    href: '/',
    icon: <HomeOutlined />
  },
  {
    href: '/policies',
    name: 'Policies'
  }
])

export default Policies
