import React from 'react'
import { Form, Input, Button, message } from 'antd'
import { useMutation } from '@apollo/react-hooks'
import { useGraphqlError, useErrors } from '../../utilities/hooks'
import Drawer from '../shared/Drawer'
import { UPDATE_CUSTOMER } from '../../graphql/mutations'

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}
const tailLayout = {
  wrapperCol: { offset: 6, span: 18 }
}

const CustomerEditDrawer = (props) => {
  const { customer, onClose } = props
  const [updateCustomer, { data, loading, error }] = useMutation(UPDATE_CUSTOMER)
  useErrors(data?.updateCustomer?.errors)
  useGraphqlError(error)

  const onFinish = async (values) => {
    const params = { variables: { input: { id: customer.id, attributes: values } } }
    const response = await updateCustomer(params)
    if (response?.data?.updateCustomer?.errors?.length === 0) {
      message.success('Customer updated.')
      onClose()
    }
  }

  const validateContactInfo = (phone, email) => {
    const contactInfoIsValid = (phone != null && phone.length > 0) || (email != null && email.length > 0)
    if (contactInfoIsValid) {
      return Promise.resolve()
    }
    return Promise.reject(new Error('A customer must have either an email or phone'))
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  return (
    <Drawer {...props} width={500}>
      <h3>Customer Edit: {customer.fullName}</h3>
      <Form {...layout} className="p-5" name="basic" initialValues={customer} onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <Form.Item label="First Name" name="firstName">
          <Input />
        </Form.Item>

        <Form.Item label="Last Name" name="lastName">
          <Input />
        </Form.Item>

        <Form.Item
          label="Phone"
          name="phone"
          validateTrigger="onBlur"
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                return validateContactInfo(value, getFieldValue('email'))
              }
            })
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          validateTrigger="onBlur"
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                return validateContactInfo(getFieldValue('phone'), value)
              }
            })
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" loading={loading}>
            Save
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  )
}

export default CustomerEditDrawer
