import React from 'react'
import { Button, Tooltip } from 'antd'
import DrawerLink from '../shared/DrawerLink/DrawerLink'
import CreateUserDrawer from './CreateUserDrawer'
import { get } from '../../utilities/storage'

const CreateUserButton = () => {
  const isSuperAdmin = get('user')?.isSuperAdmin

  return (
    <DrawerLink className="flex-grow flex justify-end" drawerElement={CreateUserDrawer} childProps={{ isSuperAdmin }} disabled={!isSuperAdmin}>
      <Tooltip title={isSuperAdmin ? null : 'You must request access to be able to create users'}>
        <Button disabled={!isSuperAdmin} type="primary">
          Create User
        </Button>
      </Tooltip>
    </DrawerLink>
  )
}

export default CreateUserButton
