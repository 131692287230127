import ReactDOM from 'react-dom/client'

// Plugins
import Apollo from './config/Apollo'
import Routes from './config/Routes'
import Translation from './config/Translation'
import { ConfigProvider, App as AntdApp } from 'antd'

// Components
import App from './App'

ReactDOM.createRoot(document.getElementById('root')).render(
  <AntdApp>
    <Translation>
      <Apollo>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#237fd3',
              colorLink: '#1890ff',
              colorSuccess: '#52c41a',
              colorWarning: '#faad14',
              colorError: '#f5222d',
              colorDisabled: 'rgba(0, 0, 0, 0.25)',
              colorHeading: 'rgba(0, 0, 0, 0.85)',
              colorText: 'rgba(0, 0, 0, 0.65)',
              colorTextSecondary: 'rgba(0, 0, 0, 0.45)',
              fontSizeBase: '14px',
              fontFeatureSettings: 'tnum',
              borderRadiusBase: '4px',
              borderColorBase: '#d9d9d9',
              btnBorderRadiusBase: '3000px',
              boxShadowBase: '0 2px 8px rgba(0, 0, 0, 0.15)'
            },
            hashed: false
          }}
        >
          <App>
            <Routes />
          </App>
        </ConfigProvider>
      </Apollo>
    </Translation>
  </AntdApp>
)

// serviceWorker.unregister()
