import React, { useCallback, useState } from 'react'

// GraphQL
import { ARCHIVE_STUDIO } from '../../graphql/mutations'

// Plugins
import { Dropdown, Tooltip, Modal, message } from 'antd'
import { DownOutlined, ExclamationCircleOutlined } from '@ant-design/icons'

// Components
import StudioLink from './StudioLink'
import StudioEditDrawer from './StudioEditDrawer'
import DrawerLink from '../shared/DrawerLink/DrawerLink'
import ButtonLink from '../shared/ButtonLink/ButtonLink'
import DuplicatePriceSheetModal from './actions/DuplicatePriceSheetModal'

// Helpers / Hooks
import { hasPermission } from '../../utilities/permissions'
import { useMutation } from '@apollo/react-hooks'

const StudioActionsDropdown = ({ studio }) => {
  const [showModal, setShowModal] = useState(false)

  const [archiveStudio] = useMutation(ARCHIVE_STUDIO, {
    update: (cache) => {
      cache.modify({
        fields: {
          studios(cachedData = [], { readField }) {
            return cachedData.items.filter((postRef) => studio.id !== readField('id', postRef))
          }
        }
      })
    }
  })

  const archive = useCallback(async () => {
    const params = { variables: { input: { id: studio.id } } }
    const response = await archiveStudio(params)
    const errors = response.data?.archiveStudio?.errors
    if (errors && errors.length > 0) return message.error(errors[0].message)

    message.success('Studio successfully archived.')
  }, [studio, archiveStudio])

  const confirmArchiving = () => {
    Modal.confirm({
      title: 'Are you sure you want to archive this studio?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      cancelText: 'No',
      onOk: archive
    })
  }

  const toggleShowModal = () => setShowModal(!showModal)

  const canManageEntities = hasPermission('admin_manage_entities')
  const canArchiveStudios = hasPermission('admin_archive_studios')

  const items = [
    { key: '0', label: <StudioLink studio={studio}>View</StudioLink> },
    {
      key: '1',
      label: (
        <DrawerLink drawerElement={StudioEditDrawer} childProps={{ studio: studio }} disabled={!canManageEntities}>
          <Tooltip title={canManageEntities ? null : 'You must request permission to be able to manage entities.'}>
            <span>Edit Studio</span>
          </Tooltip>
        </DrawerLink>
      )
    },
    {
      key: '2',
      label: (
        <ButtonLink name="Archive" disabled={!canArchiveStudios} onClick={canArchiveStudios ? confirmArchiving : null}>
          <Tooltip title={canArchiveStudios ? null : 'You must request permission to be able to archive studios.'}>
            <span>Archive</span>
          </Tooltip>
        </ButtonLink>
      )
    },
    {
      key: '3',
      label: (
        <ButtonLink name="Copy Price Sheet" color={'secondary'} disabled={!canManageEntities} onClick={canManageEntities ? toggleShowModal : null}>
          <Tooltip title={canManageEntities ? null : 'You must request permission to be able to manage entities.'}>
            <span>Copy Price Sheet</span>
          </Tooltip>
        </ButtonLink>
      )
    }
  ]

  return (
    <>
      <Dropdown menu={{ items }} trigger={['click']}>
        <span className="ant-dropdown-link">
          Actions &nbsp;
          <DownOutlined />
        </span>
      </Dropdown>
      <DuplicatePriceSheetModal isVisible={showModal} onClose={toggleShowModal} studio={studio} />
    </>
  )
}

export default StudioActionsDropdown
