import React from 'react'
import { Button, Tooltip } from 'antd'
import DrawerLink from '../shared/DrawerLink/DrawerLink'
import ZeroCreditsDrawer from './ZeroCreditsDrawer'
import { get } from '../../utilities/storage'

const ZeroButton = () => {
  const isSuperAdmin = get('user')?.isSuperAdmin

  return (
    <DrawerLink className="flex-grow flex justify-end" drawerElement={ZeroCreditsDrawer} disabled={!isSuperAdmin} childProps={{ isSuperAdmin }}>
      <Tooltip title={isSuperAdmin ? null : 'You must request access to be able to zero credits'}>
        <Button disabled={!isSuperAdmin} type="primary">
          Zero Credits
        </Button>
      </Tooltip>
    </DrawerLink>
  )
}

export default ZeroButton
