import React, { useState } from 'react'

// Plugins
import { Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'

// Helpers / Hooks
import { useDebounce } from 'react-use'

// Styles

const Search = ({ placeholder, onSearch }) => {
  const [search, setSearch] = useState()

  const [, cancel] = useDebounce(
    () => {
      if (search == null) return // Don't search when initially set
      if (search.length === 0 || search.length > 1) {
        onSearch(search)
      }
    },
    800,
    [search]
  )

  // Controls making sure we aren't searching too much
  // while typing
  const onChange = (e) => {
    const { value } = e.currentTarget
    cancel()
    setSearch(value)
  }

  // When the user presses enter we don't really care about the search
  // debounce so we go ahead and just set
  const onPressEnter = (e) => {
    const { value } = e.currentTarget
    cancel()
    onSearch(value)
  }
  return (
    <Input
      className="w-72 min-w-40 mb-2 mr-2"
      placeholder={placeholder || 'Search'}
      prefix={<SearchOutlined className="text-black opacity-25" />}
      onChange={onChange}
      onPressEnter={onPressEnter}
      allowClear
    />
  )
}

export default Search
