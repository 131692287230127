import React from 'react'
import { Tabs } from 'antd'
import { HomeOutlined, CustomerServiceOutlined } from '@ant-design/icons'
import { useQueryParams, StringParam, withDefault } from 'use-query-params'
import Info from '../requests/show/Info'
import History from '../requests/show/History'
import Comments from '../requests/show/Comments'
import useObjectQuery from '../../api/useObjectQuery'
import BaseLayout from '../layout/BaseLayout'
import Page from '../layout/Page'
import { buildBreadCrumbs } from '../../utilities/breadcrumbs'
import { SUPPORT_REQUEST_QUERY } from '../../graphql/queries'
import { useParams } from 'react-router-dom'
const { TabPane } = Tabs

const Request = () => {
  const { id } = useParams()
  const { object: request } = useObjectQuery('supportRequest', SUPPORT_REQUEST_QUERY, id)
  const [queryParams, setQueryParams] = useQueryParams({
    section: withDefault(StringParam, 'info')
  })
  const { section } = queryParams

  if (request == null)
    return (
      <BaseLayout section="reprints" breadcrumb={breadcrumb}>
        <Page loading />
      </BaseLayout>
    )

  return (
    <BaseLayout section="reprints" breadcrumb={breadcrumb}>
      <Page>
        <Tabs
          defaultActiveKey={section}
          onChange={(value) => {
            setQueryParams({ section: value })
          }}
        >
          <TabPane tab="Info" key="info">
            <Info request={request} />
          </TabPane>
          <TabPane tab="History" key="history">
            <History request={request} />
          </TabPane>
          <TabPane tab="Comments" key="comments">
            <Comments request={request} />
          </TabPane>
        </Tabs>
      </Page>
    </BaseLayout>
  )
}

const breadcrumb = buildBreadCrumbs([
  {
    href: '/',
    icon: <HomeOutlined />
  },
  {
    icon: <CustomerServiceOutlined />,
    name: 'Support'
  },
  {
    href: '/reprints',
    name: 'Reprints'
  }
])

export default Request
