import React from 'react'
import Copy from '../shared/Copy'
import { PolicyLink } from '../shared/Links'
import { dateFormatter, idFormatter } from '../../utilities/formatters'
import listProps from '../../utilities/listProps'
import { POLICIES_LIST_QUERY } from '../../graphql/queries'
import PolicyActionsDropdown from './PolicyActionsDropdown'

const policiesListProps = listProps('companyPolicies', POLICIES_LIST_QUERY, {
  columns: [
    {
      dataIndex: 'id',
      title: 'ID',
      sorter: true,
      render: (id, companyPolicy) => (
        <Copy text={id}>
          <PolicyLink companyPolicy={companyPolicy}>{idFormatter(id)}</PolicyLink>
        </Copy>
      )
    },
    {
      dataIndex: 'name',
      title: 'Name',
      sorter: true,
      render: (name) => <div>{name}</div>
    },
    {
      dataIndex: 'description',
      title: 'Description',
      sorter: true,
      render: (description) => <div>{description}</div>
    },
    {
      dataIndex: 'key',
      title: 'Key',
      sorter: true,
      render: (key) => <div>{key}</div>
    },
    {
      dataIndex: 'url',
      title: 'URL',
      sorter: true,
      render: (url) => <div>{url}</div>
    },
    {
      dataIndex: 'userChangedAt',
      title: 'User Changed At',
      render: dateFormatter
    },
    {
      dataIndex: 'customerChangedAt',
      title: 'Customer Changed At',
      render: dateFormatter
    },
    {
      render: (_, companyPolicy) => <PolicyActionsDropdown companyPolicy={companyPolicy} />
    }
  ]
})

export default policiesListProps
