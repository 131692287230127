import { useNavigate } from 'react-router-dom'
import { Layout, Spin } from 'antd'
import { PageHeader } from '@ant-design/pro-components'
import React, { useEffect } from 'react'
import { get } from '../../utilities/storage'

const { Content } = Layout

const Page = ({ children, title, subTitle, requiresAuth = true, prohibitsAuth = false, loading }) => {
  const navigate = useNavigate()
  useEffect(() => {
    const hasAuth = get('jwt')
    if (requiresAuth && !hasAuth) {
      navigate('/sign-in')
    }
    if (prohibitsAuth && hasAuth) {
      navigate('/')
    }
  }, [navigate, requiresAuth, prohibitsAuth])
  return (
    <Layout className="max-h-full overflow-hidden">
      {title && <PageHeader title={title} subTitle={subTitle} />}
      <Content className="overflow-hidden">
        {loading && (
          <div className="pt-20 text-center">
            <Spin />
          </div>
        )}
        {children}
      </Content>
    </Layout>
  )
}

export default Page
