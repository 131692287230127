import React from 'react'
import { HomeOutlined, ProfileOutlined } from '@ant-design/icons'
import BaseLayout from '../layout/BaseLayout'
import Page from '../layout/Page'
import PagedList from '../shared/PagedList'
import labListProps from '../labs/labListProps'
import { buildBreadCrumbs } from '../../utilities/breadcrumbs'

const Labs = () => {
  return (
    <BaseLayout section="labs" breadcrumb={breadcrumb}>
      <Page>
        <PagedList {...labListProps} />
      </Page>
    </BaseLayout>
  )
}

const breadcrumb = buildBreadCrumbs([
  {
    href: '/',
    icon: <HomeOutlined />
  },
  {
    icon: <ProfileOutlined />,
    name: 'Profiles'
  },
  {
    href: '/labs',
    name: 'Labs'
  }
])

export default Labs
