import { useQuery } from '@apollo/react-hooks'
import { useGraphqlError } from '../utilities/hooks'

/**
 * React hook that uses apollo query for fetching an object from our server
 * @param {string} dataKey - The key for the object .e.g. user
 * @param {any} query - GraphQL query to make
 * @param {string} id - The ID or num of the object to fetch
 * @param {object} options - The apollo QueryHookOptions
 * @returns {object} - error, loading, object
 */
const useObjectQuery = (dataKey, query, id, options) => {
  const variables = id.length < 32 ? { num: id } : { id }
  const { data, loading, error } = useQuery(query, {
    variables,
    ...(options && options)
  })
  useGraphqlError(error)
  const object = data && data[dataKey]
  return { error, loading, object }
}

export default useObjectQuery
