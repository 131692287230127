import React from 'react'

// GraphQL
import { RESET_PASSWORD_LINK } from '../../graphql/mutations'

// Plugins
import { DownOutlined } from '@ant-design/icons'
import { Button, Dropdown, Tooltip, message } from 'antd'

// Components
import UserLink from './UserLink'
import UserEditDrawer from './UserEditDrawer'
import DrawerLink from '../shared/DrawerLink/DrawerLink'
import ButtonLink from '../shared/ButtonLink/ButtonLink'

// Helpers / Hooks
import { useMutation } from '@apollo/react-hooks'
import { hasPermission } from '../../utilities/permissions'
import { useGraphqlError, useErrors } from '../../utilities/hooks'

const UserActionsDropdown = ({ user }) => {
  const [resetPasswordLink, { data, loading, error }] = useMutation(RESET_PASSWORD_LINK)
  useErrors(data?.resetPasswordLink?.errors)
  useGraphqlError(error)

  const generateResetPasswordLink = async () => {
    const params = { variables: { input: { id: user.id } } }
    const response = await resetPasswordLink(params)
    const errors = response.data?.resetPasswordLink?.errors
    if (errors?.length > 0) {
      message.error(`An error occurred while generating reset password link for user with ID ${user.id}: ${errors.map((e) => e.message).join('\n')}`)
      console.log(errors)
    } else {
      message.success('Reset password link successfully generated and copied to the clipboard.')
      navigator.clipboard.writeText(response.data?.resetPasswordLink?.resetPasswordUrl)
    }
  }

  const canManageEntities = hasPermission('admin_manage_entities')

  const items = [
    { key: '0', label: <UserLink user={user}>View</UserLink> },
    {
      key: '1',
      label: (
        <DrawerLink drawerElement={UserEditDrawer} childProps={{ user: user }} disabled={!canManageEntities}>
          <Tooltip title={canManageEntities ? null : 'You must request permission to be able to manage entities.'}>Edit User</Tooltip>
        </DrawerLink>
      )
    },
    {
      key: '2',
      label: (
        <ButtonLink disabled={loading || !canManageEntities} onClick={canManageEntities ? generateResetPasswordLink : null}>
          <Tooltip title={canManageEntities ? null : 'You must request permission to be able to manage entities.'}>Password Reset Link</Tooltip>
        </ButtonLink>
      )
    }
  ]

  return (
    <Dropdown menu={{ items }} trigger={['click']}>
      <span className="ant-dropdown-link">
        Actions &nbsp;
        <DownOutlined />
      </span>
    </Dropdown>
  )
}

export default UserActionsDropdown
