import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import { useNavigate } from 'react-router-dom'
import { Button, Card, Checkbox, Col, Form, Input, Row } from 'antd'
import { useIntl } from 'react-intl'
import photodayLogo from '../../assets/images/photoday_dark.png'
import { useErrors, useGraphqlError } from '../../utilities/hooks'
import Page from '../layout/Page'
import { set } from '../../utilities/storage'
import { USER_SIGN_IN } from '../../graphql/mutations'

const SignInPage = () => {
  const navigate = useNavigate()
  const [signIn, { data, loading, error }] = useMutation(USER_SIGN_IN, {
    onError: (error) => {
      console.log(error)
    }
  })
  useErrors(data?.userSignIn?.errors)
  useGraphqlError(error)

  const onFinish = async (values) => {
    const remember = values.remember
    delete values.remember
    const params = { variables: { input: values } }
    const response = await signIn(params)
    const {
      data: {
        userSignIn: { errors, jwt, user }
      }
    } = response
    if (errors.length > 0) return

    set('remember', remember)
    set('jwt', jwt)
    set('user', user)
    navigate('/', { replace: true })
  }

  const { formatMessage: f } = useIntl()

  return (
    <Page requiresAuth={false} prohibitsAuth={true}>
      <Row type="flex" justify="center" className="pt-20">
        <Col span={6}>
          <Card>
            <Form onFinish={onFinish} labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} initialValues={{ remember: true }}>
              <Form.Item wrapperCol={{ offset: 6, span: 12 }} className="pt-5">
                <img src={photodayLogo} alt="PhotoDay Support Admin" width="160" />
              </Form.Item>
              <Form.Item label={f({ id: 'signUp.email' })} name="email" rules={[{ required: true, message: f({ id: 'signUp.emailRequired' }) }]}>
                <Input type="email" placeholder="E-mail" />
              </Form.Item>
              <Form.Item label={f({ id: 'signUp.password' })} name="password" rules={[{ required: true, message: f({ id: 'signUp.passwordRequired' }) }]}>
                <Input type="password" placeholder="Password" />
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 6, span: 12 }} name="remember" valuePropName="checked">
                <Checkbox>{f({ id: 'signUp.remember' })}</Checkbox>
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 6, span: 12 }}>
                <Button type="primary" htmlType="submit" loading={loading}>
                  {f({ id: 'signUp.signIn' })}
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </Page>
  )
}

export default SignInPage
