import React, { useState } from 'react'

// GraphQL
import { UPDATE_STUDIO } from '../../graphql/mutations'

// Plugins
import { InputNumber, Form, Input, Button, message, Tooltip, Checkbox, Typography, Space, Select, Modal, Radio, Row, Col } from 'antd'

// Components
import Drawer from '../shared/Drawer'

// Helpers / Hooks
import { useMutation } from '@apollo/react-hooks'
import { hasPermission } from '../../utilities/permissions'
import { useGraphqlError, useErrors } from '../../utilities/hooks'

const { Option } = Select

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}
const tailLayout = {
  wrapperCol: { offset: 6, span: 18 }
}

const StudioEditDrawer = (props) => {
  const { studio, onClose } = props

  const [form] = Form.useForm()

  const [values, setValues] = useState()
  const [deduct, setDeduct] = useState(1)
  const [creditChange, setCreditChange] = useState(false)
  const [creditChangeCents, setCreditChangeCents] = useState(0)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  const hasStudioSlug = studio.studioSlug !== null
  const canChangePDFee = hasPermission('admin_manage_pd_fee')
  const canAddRefund = hasPermission('admin_manage_studio_refunds')

  const [updateStudio, { data, loading, error }] = useMutation(UPDATE_STUDIO)
  useErrors(data?.updateStudio?.errors)
  useGraphqlError(error)

  const process = async (values) => {
    const { deduct, creditChange, creditChangeCents, creditChangeReason, ...rest } = values
    let input = {
      id: studio.id,
      attributes: rest
    }
    console.log(creditChange)
    console.log(creditChangeCents)
    if (creditChange) {
      input = {
        ...input,
        deduct: deduct === 2,
        creditChangeCents,
        creditChangeReason
      }
    }
    const params = {
      variables: { input }
    }
    const response = await updateStudio(params)
    const errors = response.data?.updateStudio?.errors
    if (errors && errors.length > 0) return

    message.success('Studio updated.')
    onClose()
  }

  const onFinish = async (values) => {
    values.creditChange = creditChange
    if (creditChange) {
      setValues(values)
      toggleShowConfirmationModal()
    } else {
      process(values)
    }
  }

  const onConfirm = async () => {
    await process(values)
    setShowConfirmationModal(false)
    onClose()
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  const handleClearStudioSlug = () => {
    if (form?.getFieldValue('studioSlug')) {
      form.setFieldsValue({ studioSlug: null })
    }
  }

  const toggleCreditChange = () => {
    setCreditChange(!creditChange)
  }

  const toggleShowConfirmationModal = () => {
    setShowConfirmationModal(!showConfirmationModal)
  }

  return (
    <>
      <Drawer {...props} width={600} open={!showConfirmationModal}>
        <h3>Studio Edit: {studio.name}</h3>
        <Form {...layout} form={form} className="p-5" name="basic" initialValues={studio} onFinish={onFinish} onFinishFailed={onFinishFailed}>
          <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Studio name required' }]}>
            <Input />
          </Form.Item>

          <Form.Item label="Phone" name="phone">
            <Input />
          </Form.Item>

          <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Studio email required' }]}>
            <Input />
          </Form.Item>

          <Form.Item label="Studio Identifier" name="studioSlug" disabled>
            <Row>
              <Col flex="auto">
                <Form.Item label="Studio Identifier" name="studioSlug" noStyle disabled>
                  <Input disabled />
                </Form.Item>
              </Col>
              {hasStudioSlug && (
                <Col>
                  <Button className="ml-3" onClick={handleClearStudioSlug}>
                    Clear
                  </Button>
                </Col>
              )}
            </Row>
          </Form.Item>

          <Form.Item label="Stripe Migration Status" name="stripeMigrationStatus" labelCol={{ style: { whiteSpace: 'normal' } }}>
            <Select placeholder="status change" disabled={studio.stripeMigrationStatus === 'complete'}>
              <Option value="available">Available</Option>
              <Option value="unavailable">Unavailable</Option>
              <Option value="required">Required</Option>
            </Select>
          </Form.Item>

          <Tooltip title={'Changing this to disabled will also delete any pending SMS that are scheduled to go out.'}>
            <Form.Item {...tailLayout} name="npsEnabled" valuePropName="checked">
              <Checkbox>NPS SMS</Checkbox>
            </Form.Item>
          </Tooltip>

          <Tooltip title={'Changing this to false will remove the studio from intercom and disable syncing.'}>
            <Form.Item {...tailLayout} name="intercomEnabled" valuePropName="checked">
              <Checkbox>Intercom</Checkbox>
            </Form.Item>
          </Tooltip>

          <Tooltip title={"Changing this to false will disable syncing to salesforce. You'll need to remove them in salesforce also."}>
            <Form.Item {...tailLayout} name="salesforceSync" valuePropName="checked">
              <Checkbox>Salesforce</Checkbox>
            </Form.Item>
          </Tooltip>

          <Form.Item {...tailLayout} name="allowKnockouts" valuePropName="checked">
            <Checkbox>Knockouts</Checkbox>
          </Form.Item>

          <Tooltip title={canChangePDFee ? null : 'You must request permissions to be able to change a studios PD fee.'}>
            <Form.Item label="PD Fee Percent" name="pdFee" rules={[{ required: true, message: 'Studio pd fee required' }]}>
              <InputNumber disabled={!canChangePDFee} min={5} max={11} step={0.01} />
            </Form.Item>
          </Tooltip>

          <Tooltip title={canAddRefund ? null : 'You must request permissions to be able to add a studio refund.'}>
            <Form.Item label="Credits $" name="creditCents">
              <Space className="flex" direction="vertical" size="middle">
                <InputNumber disabled value={studio.creditCents / 100.0} step={0.01} />
                <span>({studio.creditCents} credits)</span>
                {!creditChange && (
                  <Typography.Link onClick={toggleCreditChange} disabled={!canAddRefund}>
                    Change Credits
                  </Typography.Link>
                )}
              </Space>
            </Form.Item>

            <div className={`${creditChange ? 'block' : 'hidden'}`}>
              <Form.Item label="Type" name="deduct" initialValue={deduct}>
                <Radio.Group
                  onChange={(e) => {
                    setDeduct(e.target.value)
                  }}
                  value={deduct}
                >
                  <Radio value={1}>Add Credits</Radio>
                  <Radio value={2}>Deduct Credits</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item label="Credits (cents)" name="creditChangeCents" rules={[{ required: creditChange, message: 'Credit amount required' }]}>
                <InputNumber min={50} max={500000} step={10} onChange={setCreditChangeCents} />
              </Form.Item>
              <Form.Item name="creditChangeReason" label="Change Reason" rules={[{ required: creditChange, message: 'Change reason required' }]}>
                <Select placeholder="Select a credit change reason" allowClear>
                  <Option value="system failure">System Failure</Option>
                  <Option value="post processing unacceptable">Post Processing Unacceptable</Option>
                  <Option value="account recovery">Account Recovery</Option>
                  <Option value="marketing incentive">Marketing Incentive</Option>
                  <Option value="other">Other</Option>
                </Select>
              </Form.Item>
              <Form.Item {...tailLayout} className="-mt-2">
                <Typography.Link onClick={toggleCreditChange}>Cancel Credit Change</Typography.Link>
              </Form.Item>
            </div>
          </Tooltip>

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Save
            </Button>
          </Form.Item>
        </Form>
      </Drawer>

      <Modal
        title={deduct === 1 ? 'Add Credits' : 'Deduct Credits'}
        okText="Continue"
        open={showConfirmationModal}
        onOk={onConfirm}
        onCancel={toggleShowConfirmationModal}
      >
        <p>
          You are {deduct === 1 ? 'adding' : 'deducting'} ${(creditChangeCents / 100).toFixed(2)} equal to {creditChangeCents / 10} credits to studio{' '}
          {studio.name}. Are you sure?
          <br />
          <br />
          Current credits: {studio.creditCents}
          <br />
          New credits: {deduct === 1 ? studio.creditCents + creditChangeCents : studio.creditCents - creditChangeCents}
        </p>
      </Modal>
    </>
  )
}

export default StudioEditDrawer
