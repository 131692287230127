import React from 'react'
import Copy from '../shared/Copy'
import { JobLink, StudioLink } from '../shared/Links'
import { checkFormatter, dateFormatter, idFormatter } from '../../utilities/formatters'
import listProps from '../../utilities/listProps'
import JobActionsDropdown from './JobActionsDropdown'
import { JOBS_LIST_QUERY } from '../../graphql/queries'

const jobListProps = listProps('jobs', JOBS_LIST_QUERY, {
  columns: [
    {
      dataIndex: 'id',
      title: 'ID',
      width: 110,
      render: (id, job) => (
        <Copy text={id}>
          <JobLink job={job}>{idFormatter(id)}</JobLink>
        </Copy>
      )
    },
    {
      dataIndex: 'name',
      title: 'Name',
      sorter: true,
      render: (_id, job) => <JobLink job={job} />
    },
    {
      dataIndex: 'access',
      title: 'Access',
      sorter: false,
      render: (mode) => <div>{mode}</div>
    },
    {
      dataIndex: 'studio',
      title: 'Studio',
      render: (_id, job) => <StudioLink studio={job.studio} />
    },
    {
      dataIndex: 'accessCode',
      title: 'Access Code',
      render: (code) => <div>{code}</div>
    },
    {
      dataIndex: 'autoSmsEnabled',
      title: 'Auto SMS',
      render: checkFormatter
    },
    {
      dataIndex: 'estimatedSubjectsCount',
      title: 'Est. Subjects',
      render: (estimatedSubjectsCount) => <div>{estimatedSubjectsCount}</div>
    },
    {
      dataIndex: 'date',
      title: 'Shoot Date',
      sorter: true,
      render: (date) => dateFormatter(date, true)
    },
    {
      dataIndex: 'publishedDate',
      title: 'AdvancePay Started',
      sorter: true,
      render: dateFormatter
    },
    {
      dataIndex: 'openedAt',
      title: 'Published',
      sorter: true,
      render: dateFormatter
    },

    {
      dataIndex: 'createdAt',
      title: 'Created',
      sorter: true,
      render: dateFormatter
    },
    {
      dataIndex: 'expiresAt',
      title: 'Expires',
      sorter: true,
      render: dateFormatter
    },
    {
      render: (_, job) => <JobActionsDropdown job={job} />
    }
  ]
})

export default jobListProps
