import React from 'react'
import { Checkbox, Form, Input, Button, message, Tooltip } from 'antd'
import { useMutation } from '@apollo/react-hooks'
import { useGraphqlError, useErrors } from '../../utilities/hooks'
import Drawer from '../shared/Drawer'
import { UPDATE_USER } from '../../graphql/mutations'
import { hasPermission } from '../../utilities/permissions'
import { get } from '../../utilities/storage'
import PubSub from 'pubsub-js'

const adminRoles = [
  {
    name: 'admin_manage_permissions',
    friendlyName: 'Manage User Permissions',
    description: 'Allows you to update other users permissions.'
  },
  { name: 'admin_access', friendlyName: 'Admin Access', description: 'Access to the admin site.' },
  {
    name: 'admin_manage_pd_fee',
    friendlyName: 'Change PD Fees',
    description: 'Allowed to update a studios fees.'
  },
  {
    name: 'admin_manage_entities',
    friendlyName: 'Manage Entities',
    description: 'Ability to update information about studios, jobs, users etc.'
  },
  {
    name: 'admin_verify_users',
    friendlyName: 'Verify Users',
    description: 'Can update studio users email verification.'
  },
  {
    name: 'admin_manage_refunds',
    friendlyName: 'Manage Refunds',
    description: 'Ability to issue refunds to studio users.'
  },
  {
    name: 'admin_resend_receipts',
    friendlyName: 'Resend Receipts',
    description: 'Ability to resend order receipts or digital download releases.'
  },
  {
    name: 'admin_manage_studio_refunds',
    friendlyName: 'Refund PD Credits',
    description: 'Ability to refund PhotoDay credits for studios.'
  },
  {
    name: 'admin_resend_orders',
    friendlyName: 'Resend Orders',
    description: 'Ability to resend orders to the lab.'
  },
  {
    name: 'admin_support_requests',
    friendlyName: 'Support Requests',
    description: 'Approve and deny support requests.'
  },
  {
    name: 'admin_archive_studios',
    friendlyName: 'Archive Studios',
    description: 'Ability to archive studios.'
  },
  {
    name: 'admin_update_company_policies',
    friendlyName: 'Company Policies',
    description: 'Ability to edit company policies.'
  }
]

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}
const tailLayout = {
  wrapperCol: { offset: 6, span: 18 }
}

const UserEditDrawer = (props) => {
  const { user, onClose } = props
  const [updateUser, { data, loading, error }] = useMutation(UPDATE_USER)
  useErrors(data?.updateUser?.errors)
  useGraphqlError(error)

  const canVerify = hasPermission('admin_verify_users')
  const canManagePermissions = hasPermission('admin_manage_permissions')
  const initialValues = { ...user }
  if (user.isPhotoday) {
    adminRoles.forEach((role) => {
      initialValues[`adminRoles.${role.name}`] = user.adminRoles?.includes(role.name)
    })
    delete initialValues['adminRoles']
  }

  const onFinish = async (values) => {
    const newRoles = []
    adminRoles.forEach((role) => {
      const hasRole = values[`adminRoles.${role.name}`] === true
      if (hasRole) newRoles.push(role.name)
      delete values[`adminRoles.${role.name}`]
    })
    if (user.isPhotoday) {
      values['adminRoles'] = newRoles
    }
    const params = { variables: { input: { id: user.id, attributes: values } } }
    const response = await updateUser(params)
    const errors = response.data?.updateUser?.errors
    if (errors == null || errors.length > 0) return

    const oldRoles = [...user.adminRoles]
    const updatedRoles = [...newRoles]
    // If user is logged in and updating own permissions
    // If any permissions changed just log them out
    if (user.isPhotoday && user.id === get('user')?.id && oldRoles.sort() !== updatedRoles.sort()) {
      message.success('You have been logged out due to changing your own permissions.')
      PubSub.publish('SIGN_OUT')
    } else {
      message.success('User updated.')
    }
    onClose()
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  const permissionsSection = () => {
    if (!user.isPhotoday) return <></>

    return (
      <>
        <h3>Permissions</h3>
        <Tooltip title={canManagePermissions ? null : 'You must request permission to be able to manage user permissions.'}>
          <div className="flex flex-wrap">
            {adminRoles.map((role, index) => {
              return (
                <Tooltip key={index} title={role.description}>
                  <Form.Item className="w-1/2 mb-3" name={`adminRoles.${role.name}`} valuePropName="checked">
                    <Checkbox disabled={!canManagePermissions}>{role.friendlyName}</Checkbox>
                  </Form.Item>
                </Tooltip>
              )
            })}
          </div>
        </Tooltip>
      </>
    )
  }

  return (
    <Drawer {...props} width={650}>
      <h3>User Edit: {user.fullName}</h3>
      <Form {...layout} className="p-5" name="basic" initialValues={initialValues} onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <Form.Item label="First Name" name="firstName" rules={[{ required: true, message: 'User first name required' }]}>
          <Input />
        </Form.Item>

        <Form.Item label="Last Name" name="lastName" rules={[{ required: true, message: 'User last name required' }]}>
          <Input />
        </Form.Item>

        <Form.Item label="Phone" name="phone">
          <Input />
        </Form.Item>

        <Form.Item label="Email" name="email" rules={[{ required: true, message: 'User email required' }]}>
          <Input />
        </Form.Item>

        <Tooltip title={'Changing this to false will remove the user from intercom and disable syncing.'}>
          <Form.Item {...tailLayout} name="intercomEnabled" valuePropName="checked">
            <Checkbox>Intercom</Checkbox>
          </Form.Item>
        </Tooltip>

        <Tooltip title={"Changing this to false will disable syncing to salesforce. You'll need to remove them in salesforce also."}>
          <Form.Item {...tailLayout} name="salesforceSync" valuePropName="checked">
            <Checkbox>Salesforce</Checkbox>
          </Form.Item>
        </Tooltip>

        <Tooltip title={canVerify ? null : 'You must request permissions to be able to verify users.'}>
          <Form.Item {...tailLayout} name="verified" valuePropName="checked">
            <Checkbox disabled={!canVerify}>Email Verified</Checkbox>
          </Form.Item>
        </Tooltip>

        {permissionsSection()}

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" loading={loading}>
            Save
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  )
}

export default UserEditDrawer
