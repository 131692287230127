import React from 'react'
import { Select, Spin } from 'antd'
import useListQuery from '../../../api/useListQuery'
import { PRICE_SHEET_LIST_QUERY } from '../../../graphql/queries'

const PriceSheetDropdown = ({ studio, onChange }) => {
  const { items, loading } = useListQuery('priceSheets', PRICE_SHEET_LIST_QUERY, { perPage: 99999, order: 'name', studioId: studio.id })

  const options = items.map((priceSheet) => {
    return { value: priceSheet.id, label: priceSheet.name }
  })

  return (
    <Spin spinning={loading}>
      <Select placeholder="Select a price sheet" showSearch options={options} onChange={onChange} />
    </Spin>
  )
}

export default PriceSheetDropdown
