import React from 'react'
import Image from '../shared/Image'
import DrawerLink from '../shared/DrawerLink/DrawerLink'
import StudioDrawer from './StudioDrawer'

const StudioLink = ({ studio, disabled = false, children }) => {
  if (studio == null) return <>None</>

  return (
    <DrawerLink drawerElement={StudioDrawer} childProps={{ id: studio.id }} disabled={disabled}>
      {children || (
        <span className="flex items-center">
          <Image className="pr-1" isImagizer src={studio.logoUrl} alt={`${studio.name} Logo`} size={{ width: 40, height: 40 }} />
          {studio?.reportingCode ? `${studio?.name} (${studio?.reportingCode})` : studio?.name}
        </span>
      )}
    </DrawerLink>
  )
}

export default StudioLink
