import React from 'react'
import { Button, Descriptions, Tooltip } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import useObjectQuery from '../../api/useObjectQuery'
import { humanFormatter, dateFormatter, checkFormatter, currencyFormatter } from '../../utilities/formatters'
import Drawer from '../shared/Drawer'
import { CustomerLink, CreditLink, UserLink } from '../shared/Links'
import Copy from '../shared/Copy'
import DrawerLink from '../shared/DrawerLink/DrawerLink'
import RefundEditDrawer from './RefundEditDrawer'
import { REFUND_GROUP_QUERY } from '../../graphql/queries'
import { hasPermission } from '../../utilities/permissions'

const { Item } = Descriptions

const RefundDrawer = (props) => {
  const { id } = props
  const { object: refund } = useObjectQuery('refundGroup', REFUND_GROUP_QUERY, id)
  if (refund == null) return <></>

  const studioReversal = refund.reversals.find((reversal) => reversal.targetType === 'Studio')
  const labReversal = refund.reversals.find((reversal) => reversal.targetType === 'Lab')
  const canManageRefunds = hasPermission('admin_manage_refunds')
  return (
    <Drawer {...props} width={1000}>
      <Descriptions column={1} title={`Refund: ${refund.id}`} size="small" bordered>
        <Item label="ID">
          <Copy text={refund.id}>{refund.id}</Copy>
        </Item>
        <Item label="Created By">
          <UserLink user={refund.user} />
        </Item>
        <Item label="Refund For">
          <Copy text={refund.payout.purchasable.num}>
            {refund.payout.purchasableType === 'GiftCard' && <CreditLink credit={refund.payout.purchasable}>Credit {refund.payout.purchasable.num}</CreditLink>}
            {refund.payout.purchasableType === 'Order' && <Link to={`/orders/${refund.payout.purchasable.num}`}>Order {refund.payout.purchasable.num}</Link>}
          </Copy>
        </Item>
        <Item label="Customer">
          <CustomerLink customer={refund.customer} />
        </Item>
        <Item label="Regarding">{humanFormatter(refund.regarding)}</Item>
        <Item label="Reason">{humanFormatter(refund.reason)}</Item>
        <Item label="Notes">{refund.notes}</Item>
        <Item label="Customer Amount">{currencyFormatter(refund.refund.amountCents)}</Item>
        <Item label="Customer Auto Process">{checkFormatter(refund.refund.autoProcess)}</Item>
        <Item label="Customer Completed At">{dateFormatter(refund.refund.completedAt)}</Item>
        <Item label="Customer Failed At">{dateFormatter(refund.refund.failedAt)}</Item>
        <Item label="Customer Failed Message">{refund.refund.failureMessage}</Item>
        {studioReversal && (
          <>
            <Item label="Studio Amount">{currencyFormatter(studioReversal.amountCents)}</Item>
            <Item label="Studio Auto Process">{checkFormatter(studioReversal.autoProcess)}</Item>
            <Item label="Studio Completed At">{dateFormatter(studioReversal.completedAt)}</Item>
            <Item label="Studio Failed At">{dateFormatter(studioReversal.failedAt)}</Item>
            <Item label="Studio Failed Message">{studioReversal.failureMessage}</Item>
          </>
        )}
        {labReversal && (
          <>
            <Item label="Lab Amount">{currencyFormatter(labReversal.amountCents)}</Item>
            <Item label="Lab Auto Process">{checkFormatter(labReversal.autoProcess)}</Item>
            <Item label="Lab Completed At">{dateFormatter(labReversal.completedAt)}</Item>
            <Item label="Lab Failed At">{dateFormatter(labReversal.failedAt)}</Item>
            <Item label="Lab Failed Message">{labReversal.failureMessage}</Item>
          </>
        )}
      </Descriptions>
      <DrawerLink className="pt-5 justify-end" drawerElement={RefundEditDrawer} childProps={{ refund: refund }} disabled={!canManageRefunds}>
        <Tooltip title={canManageRefunds ? null : 'You must request permission to be able to manage refunds.'}>
          <Button disabled={!canManageRefunds} type="primary" icon={<EditOutlined />}>
            Edit
          </Button>
        </Tooltip>
      </DrawerLink>
    </Drawer>
  )
}

export default RefundDrawer
