import React, { useCallback } from 'react'

// Plugins
import PubSub from 'pubsub-js'
import { DownOutlined } from '@ant-design/icons'
import { Dropdown, message, Tooltip, Popconfirm } from 'antd'

// GraphQL
import { DELETE_ORDER, DESTROY_CUSTOMER } from '../../graphql/mutations'
import { CUSTOMER_SMS_CODE_QUERY } from '../../graphql/queries'

// Components
import CustomerLink from './CustomerLink'
import CustomerEditDrawer from './CustomerEditDrawer'
import DrawerLink from '../shared/DrawerLink/DrawerLink'
import ButtonLink from '../shared/ButtonLink/ButtonLink'

// Helpers / Hooks
import { useMutation } from '@apollo/react-hooks'
import { hasPermission } from '../../utilities/permissions'
import { useErrors, useGraphqlError } from '../../utilities/hooks'
import useImperativeQuery from '../../utilities/useImperativeQuery'

const CustomerActionsDropdown = ({ customer }) => {
  const customerCartId = customer.cartId
  const canManageEntities = hasPermission('admin_manage_entities')

  const smsQuery = useImperativeQuery(CUSTOMER_SMS_CODE_QUERY, { variables: { id: customer.id } })

  const [deleteOrder, { data: dataDeleteOrder, error: errorDeleteOrder, loading: loadingDeleteOrder }] = useMutation(DELETE_ORDER, {
    onError: (error) => {
      message.error(`Error while deleting cart: ${error?.message}`)
    },
    onCompleted: (data) => {
      if (data.deleteOrder?.errors?.length === 0) message.success('Cart Deleted.')
    }
  })
  useGraphqlError(errorDeleteOrder)
  useErrors(dataDeleteOrder?.deleteOrder?.errors)

  const [destroyCustomer, { error: errorDestroyCustomer, loading: loadingDestroyCustomer }] = useMutation(DESTROY_CUSTOMER, {
    onError: (error) => {
      message.error(`Error while deleting account: ${error?.message}`)
    },
    onCompleted: (data) => {
      if (data.destroyCustomer?.errors?.length === 0) {
        message.success('Account Deleted.')
        PubSub.publish('PAGED_LIST_REFRESH')
      } else {
        message.error(`Error while deleting account: ${data.destroyCustomer?.errors[0]?.message}`)
      }
    }
  })
  useGraphqlError(errorDestroyCustomer)

  const getSmsCode = useCallback(async () => {
    const result = await smsQuery()
    const code = result.data.customer.smsCode
    if (code) {
      message.success(`SMS code for ${customer.phone} is ${result.data.customer.smsCode}`, 15)
    } else {
      message.warn('No recent code found. Have the customer try to login again')
    }
  }, [customer, smsQuery])

  const handleDestroyCustomer = () => {
    destroyCustomer({ variables: { input: { id: customer.id } } })
  }

  const handleDeleteCart = () => {
    deleteOrder({ variables: { input: { id: customerCartId } } })
  }

  const items = [
    { key: '0', label: <CustomerLink customer={customer}>View</CustomerLink> },
    {
      key: '1',
      label: (
        <DrawerLink drawerElement={CustomerEditDrawer} childProps={{ customer: customer }} disabled={!canManageEntities}>
          <Tooltip title={canManageEntities ? null : 'You must request permission to be able to manage entities.'}>Edit Customer</Tooltip>
        </DrawerLink>
      )
    },
    {
      key: '2',
      label: (
        <ButtonLink onClick={getSmsCode} disabled={!canManageEntities}>
          <Tooltip title={canManageEntities ? null : 'You must request permission to be able to manage entities.'}>Get SMS Code</Tooltip>
        </ButtonLink>
      )
    },
    {
      key: '3',
      label: (
        <>
          {true ? (
            <Popconfirm
              className={`${!canManageEntities ? 'ant__pop-confirm--disabled' : ''}`}
              title={`Are you sure you want to clear this customer's cart`}
              disabled={!canManageEntities}
              loading={loadingDeleteOrder}
              onConfirm={handleDeleteCart}
            >
              <Tooltip title={canManageEntities ? null : 'You must request permission to be able to manage entities.'}>Clear Cart</Tooltip>
            </Popconfirm>
          ) : null}
        </>
      )
    },
    {
      key: '4',
      label: (
        <Popconfirm
          className={`${!canManageEntities ? 'ant__pop-confirm--disabled' : ''}`}
          title={`Are you sure you want to clear this customer's cart`}
          disabled={!canManageEntities}
          loading={loadingDestroyCustomer}
          onConfirm={handleDestroyCustomer}
        >
          <Tooltip title={canManageEntities ? null : 'You must request permission to be able to manage entities.'}>Delete Account</Tooltip>
        </Popconfirm>
      )
    }
  ]

  return (
    <Dropdown menu={{ items }} trigger={['click']}>
      <span className="ant-dropdown-link">
        Actions &nbsp;
        <DownOutlined />
      </span>
    </Dropdown>
  )
}

export default CustomerActionsDropdown
