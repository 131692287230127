import React from 'react'
import DrawerLink from '../shared/DrawerLink/DrawerLink'
import PolicyDrawer from './PolicyDrawer'

const PolicyLink = ({ companyPolicy, disabled = false, children }) => {
  return (
    <DrawerLink drawerElement={PolicyDrawer} childProps={{ id: companyPolicy.id }} disabled={disabled}>
      {children || companyPolicy.name}
    </DrawerLink>
  )
}

export default PolicyLink
