import React from 'react'

// Plugins
import { Dropdown, Tooltip } from 'antd'
import { DownOutlined } from '@ant-design/icons'

// Components
import RefundLink from './RefundLink'
import DrawerLink from '../shared/DrawerLink/DrawerLink'
import RefundEditDrawer from './RefundEditDrawer'

// Helpers / Hooks
import { hasPermission } from '../../utilities/permissions'

const RefundActionsDropdown = ({ refund }) => {
  const canManageRefunds = hasPermission('admin_manage_refunds')

  const items = [
    { key: '0', label: <RefundLink refund={refund}>View</RefundLink> },
    {
      key: '1',
      label: (
        <DrawerLink drawerElement={RefundEditDrawer} childProps={{ refund: refund }} disabled={!canManageRefunds}>
          <Tooltip title={canManageRefunds ? null : 'You must request permission to be able to manage refunds.'}>
            <span>Edit Refund</span>
          </Tooltip>
        </DrawerLink>
      )
    }
  ]

  return (
    <Dropdown menu={{ items }} trigger={['click']}>
      <span className="ant-dropdown-link">
        Actions &nbsp;
        <DownOutlined />
      </span>
    </Dropdown>
  )
}

export default RefundActionsDropdown
