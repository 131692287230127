import React from 'react'
import { checkFormatter, dateFormatter, emailFormatter, idFormatter, phoneFormatter } from '../../utilities/formatters'
import listProps from '../../utilities/listProps'
import Copy from '../shared/Copy'
import { UserLink, StudioLink } from '../shared/Links'
import StudioActionsDropdown from './StudioActionsDropdown'
import { STUDIO_LIST_QUERY } from '../../graphql/queries'

const studioListProps = listProps('studios', STUDIO_LIST_QUERY, {
  columns: [
    {
      dataIndex: 'id',
      title: 'ID',
      width: 110,
      render: (id, studio) => (
        <Copy text={id}>
          <StudioLink studio={studio}>{idFormatter(id)}</StudioLink>
        </Copy>
      )
    },
    {
      dataIndex: 'name',
      title: 'Name',
      sorter: true,
      render: (_name, studio) => <StudioLink studio={studio} />
    },
    {
      dataIndex: 'owner.name',
      title: 'Owner',
      sorter: true,
      render: (_name, studio) => <UserLink user={studio.owner} />
    },
    {
      dataIndex: 'phone',
      title: 'Phone',
      sorter: true,
      render: phoneFormatter
    },
    {
      dataIndex: 'email',
      title: 'E-mail',
      sorter: true,
      render: emailFormatter
    },
    {
      dataIndex: 'npsEnabled',
      title: 'NPS SMS',
      render: checkFormatter
    },
    {
      dataIndex: 'intercomEnabled',
      title: 'Intercom',
      render: checkFormatter
    },
    {
      dataIndex: 'salesforceSync',
      title: 'Salesforce',
      render: checkFormatter
    },
    {
      dataIndex: 'allowKnockouts',
      title: 'Knockouts',
      render: checkFormatter
    },
    {
      dataIndex: 'createdAt',
      title: 'Created',
      sorter: true,
      render: dateFormatter
    },
    {
      render: (_, studio) => <StudioActionsDropdown studio={studio} />
    }
  ]
})

export default studioListProps
