import React from 'react'
import { Button, Descriptions, Tooltip } from 'antd'
import useObjectQuery from '../../api/useObjectQuery'
import { dateFormatter, currencyFormatter } from '../../utilities/formatters'
import Drawer from '../shared/Drawer'
import { JobLink, CustomerLink, StudioLink } from '../shared/Links'
import Copy from '../shared/Copy'
import DrawerLink from '../shared/DrawerLink/DrawerLink'
import CreditEditDrawer from './CreditEditDrawer'
import { EditOutlined } from '@ant-design/icons'
import { GIFT_CARD_QUERY } from '../../graphql/queries'
import { hasPermission } from '../../utilities/permissions'

const { Item } = Descriptions

const CreditDrawer = (props) => {
  const { id } = props
  const { object: giftCard } = useObjectQuery('giftCard', GIFT_CARD_QUERY, id)
  if (giftCard == null) return <></>

  const canManageEntities = hasPermission('admin_manage_entities')
  return (
    <Drawer {...props} width={600}>
      <Descriptions column={1} title={`Credit: ${giftCard.num}`} bordered>
        <Item label="Num">
          <Copy text={giftCard.num}>{giftCard.num}</Copy>
        </Item>
        <Item label="ID">
          <Copy text={giftCard.id}>{giftCard.id}</Copy>
        </Item>
        <Item label="Studio">
          <StudioLink studio={giftCard.job.studio} />
        </Item>
        <Item label="Job">
          <JobLink job={giftCard.job} />
        </Item>
        <Item label="Customer">
          <CustomerLink customer={giftCard.customer} />
        </Item>
        <Item label="Email">
          <Copy text={giftCard.email}>{giftCard.email}</Copy>
        </Item>
        <Item label="Phone">
          <Copy text={giftCard.phone}>{giftCard.phone}</Copy>
        </Item>
        <Item label="Subject">{giftCard.subjectDetails}</Item>
        <Item label="Amount">{currencyFormatter(giftCard.amountCents)}</Item>
        <Item label="Remaining">{currencyFormatter(giftCard.remainingAmountCents)}</Item>
        <Item label="PhotoDay Collected">{currencyFormatter(giftCard.photodayInitialProfitCents)}</Item>
        <Item label="Studio Collected">{currencyFormatter(giftCard.studioInitialProfitCents)}</Item>
        <Item label="Stripe Fee">{currencyFormatter(giftCard.stripeProfit)}</Item>
        <Item label="Created At">{dateFormatter(giftCard.createdAt, true)}</Item>
        <Item label="Expiration">{dateFormatter(giftCard.expiration, true)}</Item>
      </Descriptions>
      <DrawerLink className="pt-5 justify-end" drawerElement={CreditEditDrawer} childProps={{ giftCard: giftCard }} disabled={!canManageEntities}>
        <Tooltip title={canManageEntities ? null : 'You must request access to be able to manage entities'}>
          <Button disabled={!canManageEntities} type="primary" icon={<EditOutlined />}>
            <span>Edit</span>
          </Button>
        </Tooltip>
      </DrawerLink>
    </Drawer>
  )
}

export default CreditDrawer
