import React from 'react'
import Copy from '../shared/Copy'
import { CustomerLink } from '../shared/Links'
import { dateFormatter, idFormatter, phoneFormatter, emailFormatter } from '../../utilities/formatters'
import listProps from '../../utilities/listProps'
import CustomerActionsDropdown from './CustomerActionsDropdown'
import { CUSTOMERS_LIST_QUERY } from '../../graphql/queries'

const customerListProps = listProps('customers', CUSTOMERS_LIST_QUERY, {
  columns: [
    {
      dataIndex: 'id',
      title: 'ID',
      width: 110,
      render: (id, customer) => (
        <Copy text={id}>
          <CustomerLink customer={customer}>{idFormatter(id)}</CustomerLink>
        </Copy>
      )
    },
    {
      dataIndex: 'fullName',
      title: 'Name',
      sorter: true,
      render: (fullName, customer) => <CustomerLink customer={customer}>{fullName}</CustomerLink>
    },
    {
      dataIndex: 'phone',
      title: 'Phone',
      sorter: true,
      render: phoneFormatter
    },
    {
      dataIndex: 'email',
      title: 'E-mail',
      sorter: true,
      render: emailFormatter
    },
    {
      dataIndex: 'ordersCount',
      title: 'Order Count'
    },
    {
      dataIndex: 'createdAt',
      title: 'Created',
      sorter: true,
      render: dateFormatter
    },
    {
      render: (_, customer) => <CustomerActionsDropdown customer={customer} />
    }
  ]
})

export default customerListProps
