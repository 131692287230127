import React, { useCallback } from 'react'

// GraphQL
import { EXPORT_SUPPORT_REQUESTS } from '../../graphql/mutations'

// Plugins
import dayjs from 'dayjs'
import { Button, message } from 'antd'

// Helpers / Hooks
import { useMutation } from '@apollo/react-hooks'

const ExportButton = ({ tableParams }) => {
  const [exportSupportRequests] = useMutation(EXPORT_SUPPORT_REQUESTS)

  const exportRequests = useCallback(async () => {
    const inputParams = {
      status: tableParams.status,
      lab: tableParams.lab,
      studio: tableParams.studio,
      dateFrom: tableParams.dateFrom,
      dateTo: tableParams.dateTo
    }

    const params = { variables: { input: inputParams } }
    const response = await exportSupportRequests(params)
    const errors = response.data?.exportSupportRequests?.errors
    if (errors && errors.length > 0) {
      message.error('An error occurred while exporting the data. Please contact admin support.')
      return
    }

    const data = response.data?.exportSupportRequests?.data
    let link = document.createElement('a')
    link.href = `data:application/octet-stream;base64,${data}`
    const filename = `Support Requests - ${dayjs().format('lll')}.csv`
    link.download = filename
    link.click()
  }, [tableParams, exportSupportRequests])

  return (
    <Button type="primary" onClick={exportRequests}>
      Export
    </Button>
  )
}

export default ExportButton
