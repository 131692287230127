import React, { useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { Modal, Table, Result, message } from 'antd'
import { checkFormatter, dateFormatter } from '../../../utilities/formatters'
import { useErrors, useGraphqlError } from '../../../utilities/hooks'
import useObjectQuery from '../../../api/useObjectQuery'
import { SEND_ORDER_TO_LAB } from '../../../graphql/mutations'
import { ORDER_SENT_ORDERS_QUERY } from '../../../graphql/queries'
const { Column } = Table

const SendToLabModal = (props) => {
  const { object: order } = useObjectQuery('order', ORDER_SENT_ORDERS_QUERY, props.order.num)
  const [sendToLab, { data, loading, error }] = useMutation(SEND_ORDER_TO_LAB)
  const [sent, setSent] = useState(false)
  useErrors(data?.sendOrderToLab?.errors)
  useGraphqlError(error)
  const { close } = props

  if (order == null) return <></>

  const richmond = order.lab?.key === 'richmond'
  const sentOrders = order?.sentOrders?.map((sentOrder) => {
    return { ...sentOrder, ...{ key: sentOrder.id } }
  })

  const onSubmit = async () => {
    const params = { variables: { input: { id: order.id } } }
    const response = await sendToLab(params)
    if (response?.data?.sendOrderToLab?.errors?.length === 0) {
      message.success('Order sent.')
      setSent(true)
    }
  }

  let modalProps = props
  if (sent) modalProps = { ...modalProps, ...{ footer: null } }

  return (
    <Modal
      {...modalProps}
      title={`Send order ${order.num} to ${order.lab.name}`}
      destroyOnClose={true}
      onOk={onSubmit}
      onCancel={() => {
        setSent(false)
        close()
      }}
      okText="Send"
      cancelText="Cancel"
      confirmLoading={loading}
    >
      {sent && <Result status="success" title={`Order ${order.num} has been sent to ${order.lab.name}.`} />}
      {!sent && richmond && <span>We don't store sent orders for Richmond since they pull orders from us.</span>}
      {!sent && !richmond && sentOrders.length === 0 && <span>No sent orders</span>}
      {!sent && !richmond && sentOrders.length > 0 && (
        <Table dataSource={sentOrders} pagination={false}>
          <Column title="Sent" key="createdAt" render={dateFormatter} />
          <Column title="Result" key="sentToPrinter" render={(value) => checkFormatter(value)} />
        </Table>
      )}
    </Modal>
  )
}

export default SendToLabModal
