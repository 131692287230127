import React from 'react'

// GraphQL
import { UPDATE_REPRINT_REQUEST, UPDATE_SMS_CAMPAIGN_REQUEST } from '../../graphql/mutations'

// Plugins
import PubSub from 'pubsub-js'
import { Form, Button, message, Input } from 'antd'

// Components
import Drawer from '../shared/Drawer'

// Helpers / Hooks
import { useMutation } from '@apollo/react-hooks'
import { useGraphqlError, useErrors } from '../../utilities/hooks'

// Constants
const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}
const tailLayout = {
  wrapperCol: { offset: 6, span: 18 }
}

const { TextArea } = Input

const DenyDrawer = (props) => {
  const { request, onClose } = props
  const [updateSmsCampaignRequest, { data: updateSmsData, loading: updateSmsLoading, error: updateSmsCampaignError }] = useMutation(
    UPDATE_SMS_CAMPAIGN_REQUEST,
    { errorPolicy: 'all' }
  )
  useErrors(updateSmsData?.updateSmsCampaignRequest?.errors)
  useGraphqlError(updateSmsCampaignError)

  const [updateReprintRequest, { data: updateReprintData, loading: updateReprintLoading, error: updateReprintError }] = useMutation(UPDATE_REPRINT_REQUEST, {
    errorPolicy: 'all'
  })
  useErrors(updateReprintData?.updateReprintRequest?.errors)
  useGraphqlError(updateReprintError)

  const order = request.orderReprint?.order
  const isReprint = request.requestType === 'reprint'
  const loading = updateSmsLoading || updateReprintLoading

  const onFinish = async (values) => {
    const params = {
      variables: {
        input: {
          id: request.id,
          status: 'denied',
          comment: values.comment
        }
      }
    }

    if (isReprint) {
      const response = await updateReprintRequest(params)
      const errors = response.data?.updateReprintRequest?.errors
      if (errors && errors.length > 0) return message.error(errors[0].message)
    } else {
      const response = await updateSmsCampaignRequest(params)
      const errors = response.data?.updateSmsCampaignRequest?.errors
      if (errors && errors.length > 0) return message.error(errors[0].message)
    }

    message.success('Request updated.')
    PubSub.publish('PAGED_LIST_REFRESH')
    onClose()
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  return (
    <Drawer {...props} width={600}>
      {isReprint ? <h3>Deny request for order {order.num}</h3> : <h3>Deny request for custom promo {request.studioPromotion.promotion.name}</h3>}
      <Form {...layout} className="p-5" name="basic" initialValues={request} onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <Form.Item label="Reason" name={'comment'}>
          <TextArea rows={4} required={true}></TextArea>
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" loading={loading}>
            Deny
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  )
}

export default DenyDrawer
