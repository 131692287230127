import React from 'react'
import messages from './locales/en.json'
import { IntlProvider } from 'react-intl'

const Translation = ({ children }) => {
  return (
    <IntlProvider locale="en" messages={messages}>
      {children}
    </IntlProvider>
  )
}

export default Translation
