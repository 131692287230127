import React, { useState, useEffect } from 'react'

// GraphQL
import { UPDATE_JOB } from '../../graphql/mutations'

// Plugins
import dayjs from 'dayjs'
import { Form, Button, message, Checkbox, Tooltip, DatePicker, Modal } from 'antd'

// Components
import Drawer from '../shared/Drawer'

// Helpers / Hooks
import { useMutation } from '@apollo/react-hooks'
import { useGraphqlError, useErrors } from '../../utilities/hooks'

// Constants
import { hasPermission } from '../../utilities/permissions'

const canManageEntities = hasPermission('admin_manage_entities')

const JobEditDrawer = (props) => {
  const { job, onClose } = props

  const [initialJobValues, setInitialJobValues] = useState(null)
  const [newJobValues, setNewJobValues] = useState(null)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [updateJob, { data, loading, error }] = useMutation(UPDATE_JOB, {
    onCompleted: (data) => {
      if (data?.updateJob?.errors?.length === 0) {
        message.success('Job updated.')
        onClose()
      }
    }
  })
  useErrors(data?.updateJob?.errors)
  useGraphqlError(error)

  const initialValues = {
    ...job,
    openedAt: job?.openedAt ? dayjs(job.openedAt, 'YYYY-MM-DD') : null,
    yearbookSelectionDeadline: job?.yearbookSelectionDeadline ? dayjs(job.yearbookSelectionDeadline, 'YYYY-MM-DD') : null
  }

  const process = async (values) => {
    const params = { variables: { input: { id: job.id, attributes: { ...values } } } }

    await updateJob(params)
  }

  const onFinish = async (values) => {
    const keys = Object.keys(values)
    const newValues = {}

    // Check for changes on the form
    keys.forEach((key) => {
      const currentValue = values[key]
      const initialValue = initialJobValues[key]
      const isDate = dayjs.isDayjs(currentValue)

      if (isDate) {
        // Format DayJs to ISO strings
        const currentDate = dayjs(currentValue, 'YYYY-MM-DD').toISOString()
        const initialDate = dayjs(initialValue, 'YYYY-MM-DD').toISOString()
        const didDateChange = currentDate !== initialDate

        if (didDateChange) {
          newValues[key] = values[key].toISOString()
        }
      } else if (currentValue !== initialValue) {
        newValues[key] = values[key]
      }
    })

    // Process if there are changes
    if (Object.keys(newValues).length) {
      setNewJobValues(newValues)

      if (newValues.openedAt) {
        toggleShowConfirmationModal()
      } else {
        process(newValues)
      }
    } else {
      message.info('No changes detected.')
    }
  }

  const onConfirm = async () => {
    await process(newJobValues)
    setShowConfirmationModal(false)
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
    message.error('Failed to save changes.')
  }

  const toggleShowConfirmationModal = () => {
    setShowConfirmationModal(!showConfirmationModal)
  }

  useEffect(() => {
    if (job) setInitialJobValues(job)
  }, [job])

  return (
    <>
      <Drawer {...props} width={500} open={!showConfirmationModal}>
        <h3>Job Edit: {job.fullName}</h3>
        <Form className="p-5" name="job-edit" initialValues={initialValues} onFinish={onFinish} onFinishFailed={onFinishFailed}>
          <Tooltip title={'Changing this to disabled will stop marketing SMS but transactional SMS will remain supported.'}>
            <Form.Item label="Auto SMS" labelAlign="left" name="autoSmsEnabled" valuePropName="checked">
              <Checkbox />
            </Form.Item>
          </Tooltip>
          <Tooltip
            title={'Changing this date could potentially send duplicate texts to existing subscribers if users were subscribed during the initial period.'}
          >
            <Form.Item label="Auto SMS Start Date" labelAlign="left" name="openedAt">
              <DatePicker
                allowClear={initialJobValues?.openedAt ? false : true}
                disabledDate={(current) => {
                  const customDate = dayjs().format('YYYY-MM-DD')
                  return current && current < dayjs(customDate, 'YYYY-MM-DD')
                }}
              />
            </Form.Item>
          </Tooltip>
          <Form.Item>
            {job?.yearbookSelectionEnabled && canManageEntities && (
              <Form.Item label="Yearbook Selection Deadline" name="yearbookSelectionDeadline">
                <DatePicker
                  allowClear={false}
                  disabledDate={(current) => {
                    const customDate = dayjs().format('YYYY-MM-DD')
                    return current && current < dayjs(customDate, 'YYYY-MM-DD')
                  }}
                />
              </Form.Item>
            )}
          </Form.Item>

          <Form.Item>
            <Button className="ml-auto block" type="primary" htmlType="submit" loading={loading}>
              Save
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
      <Modal title="Auto SMS Start Date" okText="Continue" open={showConfirmationModal} onOk={onConfirm} onCancel={toggleShowConfirmationModal} zIndex={1100}>
        <p>
          Changing the job start date to <b>{dayjs(newJobValues?.openedAt)?.format('YYYY-MM-DD')}</b> could potentially send duplicate texts to existing
          subscribers if users were subscribed during the initial period. Are you sure?
        </p>
      </Modal>
    </>
  )
}

export default JobEditDrawer
