import React from 'react'
import { Descriptions } from 'antd'
import { currencyFormatter, boolFormatter, stripeTransferLink } from '../../../utilities/formatters'

const { Item } = Descriptions

const Payouts = ({ order }) => {
  return (
    <div className="p-0">
      {order.payout != null && (
        <Descriptions title="Order Payouts" size="small" column={1} bordered>
          <Item label="Lab">{currencyFormatter(order.payout.labCents)}</Item>
          <Item label="Lab Stripe Transfer">{stripeTransferLink(order.payout.stripeLabTransfer)}</Item>
          <Item label="Studio">{currencyFormatter(order.payout.studioCents)}</Item>
          <Item label="Studio Stripe Transfer">{stripeTransferLink(order.payout.stripeStudioTransfer)}</Item>
          <Item label="PhotoDay">{currencyFormatter(order.payout.photodayCents)}</Item>
          <Item label="Stripe">{currencyFormatter(order.payout.stripeCents)}</Item>
          <Item label="Total">{currencyFormatter(order.totalCents)}</Item>
          <Item label="Payouts Complete">{boolFormatter(order.payout.payoutsComplete)}</Item>
        </Descriptions>
      )}
      {!order.payout && (
        <p>
          This order does not contain a payout. It may have been a test order. If you think this order should have had a payout please contact engineering team.
        </p>
      )}
    </div>
  )
}

export default Payouts
