import React, { useState } from 'react'

// Plugins
import { Layout } from 'antd'

// Components
import Header from './header/Header'
import Navigation from './Navigation'

// Helpers / Hooks
import { get, set } from '../../utilities/storage'
import { useWindowSize } from '@react-hook/window-size/throttled'

// Images
import photodayLogo from '../../assets/images/photoday.png'
import photodayLogoSmall from '../../assets/images/photoday_small.png'

// Constants
const { Sider } = Layout

const BaseLayout = ({ children, section, breadcrumb }) => {
  const [width] = useWindowSize()
  const [refresh, setRefresh] = useState(false)
  const collapsed = get('collapsed') || width < 600
  const logo = collapsed ? photodayLogoSmall : photodayLogo

  return (
    <Layout className="h-screen w-screen overflow-hidden" hasSider>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={() => {
          set('collapsed', !collapsed)
          setRefresh(!refresh)
        }}
      >
        <div className="flex items-center justify-center w-full">
          <img className="my-5 mt-6 mx-0 w-1/2" src={logo} alt="PhotoDay Support Admin" />
        </div>
        <Navigation section={section} selectSection={!collapsed} />
      </Sider>
      <Layout className="max-h-full">
        <Header breadcrumb={breadcrumb} />
        {children}
      </Layout>
    </Layout>
  )
}

export default BaseLayout
