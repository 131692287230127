import React from 'react'
import { Card, Timeline } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import { SUPPORT_REQUEST_EVENT_LIST_QUERY } from '../../../graphql/queries'

const { Item } = Timeline

const RequestHistory = ({ request }) => {
  const { data: eventsData } = useQuery(SUPPORT_REQUEST_EVENT_LIST_QUERY, {
    variables: { eventType: 'status_change', supportRequestId: request.id, perPage: 9999 }
  })
  const events = eventsData?.supportRequestEvents?.items

  return (
    <>
      {events && (
        <Card>
          <Timeline>
            {events.map(function (event, i) {
              const formatComment = (comment) => comment?.replace(/\n/g, '<br />')

              return (
                <Item key={`item-${i}`}>
                  {<span dangerouslySetInnerHTML={{ __html: formatComment(event.comment) }} />} ({event.createdAt})
                </Item>
              )
            })}
          </Timeline>
        </Card>
      )}
    </>
  )
}

export default RequestHistory
