import { get } from './storage'

/**
 * Checks if the current logged in user has permission to do something
 * @param {string} name The permissions to check for
 * @return true or false
 */
export const hasPermission = (name) => {
  return get('user')?.adminRoles?.includes(name)
}
