import { React, useState } from 'react'

// GraphQL
import { useQuery, useMutation } from '@apollo/react-hooks'
import { JOB_MARKETING_EVENTS_QUERY } from '../../graphql/queries'
import { JOB_SEND_MARKETING_EMAIL } from '../../graphql/mutations'

// Plugins
import { Form, Input, Button, Row, Col, Radio, message } from 'antd'

// Components
import Drawer from '../shared/Drawer'

// Helpers / Hooks
import { useGraphqlError, useErrors } from '../../utilities/hooks'

const JobMarketingEmailsDrawer = (props) => {
  const { jobId } = props
  const [form] = Form.useForm()

  const { data, loading, error } = useQuery(JOB_MARKETING_EVENTS_QUERY, { variables: { jobId: jobId } })

  useErrors(data?.jobMarketingEvents?.errors)
  useGraphqlError(error)

  const [jobSendMarketingEmail, { emailData, emailLoading, emailError }] = useMutation(JOB_SEND_MARKETING_EMAIL)
  useErrors(emailData?.jobSendMarketingEmail?.errors)
  useGraphqlError(emailError)

  const items = data?.jobMarketingEvents || []
  const [selectedId, setSelectedId] = useState(null)

  const onFinish = async (values) => {
    const params = { variables: { input: { jobId: jobId, marketingEventId: values.eventId, email: values.email } } }
    if (values.eventId.startsWith('series--')) {
      const parts = values.eventId.split('--')
      params.variables.input.interval = parseInt(parts[1])
      params.variables.input.marketingEventId = parts[2]
    }

    const response = await jobSendMarketingEmail(params)
    if (!!response?.data?.jobSendMarketingEmail?.success) {
      message.success('Email Sent')
    } else {
      console.error('Error while sending email:')
      const errors = response?.data?.jobSendMarketingEmail?.errors
      console.dir(errors)
      message.error(`Error sending email: ${errors[0].message}`)
    }
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  // NOTE(jpr): the id logic is a hack to get series and non-series to work together in a
  // single list. basically, if we have an id like `series-{number}-{UUID}` its a series,
  // but if its just `{UUID}` then its a 'regular' email
  const explodedItems = (items) => {
    const res = []
    items.forEach((it) => {
      if (it.series) {
        it.seriesIntervals.forEach((interval) => {
          res.push({ id: `series--${interval}--${it.id}`, code: `${it.code} (${interval} day)` })
        })
      } else {
        res.push({ id: it.id, code: it.code })
      }
    })
    return res
  }

  return (
    <>
      <Drawer {...props} width={500}>
        <h3>Send Marketing Email</h3>
        <Form form={form} name="basic" onFinish={onFinish} onFinishFailed={onFinishFailed}>
          <Form.Item className="mb-0">
            <Row wrap={false} gutter={[12]}>
              <Col>
                <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Email required' }]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col>
                <Button type="primary" htmlType="submit" loading={loading || emailLoading} disabled={emailLoading}>
                  Save
                </Button>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item name="eventId" label="Marketing Email" rules={[{ required: true, message: 'Must choose a marketing email' }]}>
            <Radio.Group onChange={(e) => setSelectedId(e.target.value)} value={selectedId}>
              {explodedItems(items).map((it) => {
                return (
                  <Row key={it.id}>
                    <Radio value={it.id}>{it.code}</Radio>
                  </Row>
                )
              })}
            </Radio.Group>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  )
}

export default JobMarketingEmailsDrawer
