import React from 'react'
import { Link } from 'react-router-dom'
import Copy from '../shared/Copy'
import { CustomerLink, UserLink, CreditLink, RefundLink } from '../shared/Links'
import { dateFormatter, humanFormatter, currencyFormatter, truncateFormatter, checkFormatter } from '../../utilities/formatters'
import listProps from '../../utilities/listProps'
import RefundActionsDropdown from './RefundActionsDropdown'
import { REFUND_GROUPS_LIST_QUERY } from '../../graphql/queries'

const refundListProps = listProps('refundGroups', REFUND_GROUPS_LIST_QUERY, {
  searchPlaceholder: 'Search Refunds...',
  columns: [
    {
      dataIndex: 'id',
      title: 'ID',
      width: 110,
      render: (id, refund) => (
        <Copy text={id}>
          <RefundLink refund={refund}>{id.split('-')[0]}</RefundLink>
        </Copy>
      )
    },
    {
      dataIndex: 'payout.purchasable.num',
      title: 'Refund For',
      render: (_, refund) => (
        <Copy text={refund.payout.purchasable.num}>
          {refund.payout.purchasableType === 'GiftCard' && <CreditLink credit={refund.payout.purchasable}>Credit {refund.payout.purchasable.num}</CreditLink>}
          {refund.payout.purchasableType === 'Order' && <Link to={`/orders/${refund.payout.purchasable.num}`}>Order {refund.payout.purchasable.num}</Link>}
        </Copy>
      )
    },
    {
      dataIndex: 'customer.fullName',
      title: 'Customer',
      render: (_, refund) => <CustomerLink customer={refund.customer} />
    },
    {
      dataIndex: 'refund.amountCents',
      title: 'Amount',
      render: (_, refund) => currencyFormatter(refund.refund.amountCents)
    },
    {
      dataIndex: 'user.id',
      title: 'Issued By',
      render: (_, refund) => <UserLink user={refund.user} />
    },
    {
      dataIndex: 'regarding',
      title: 'Regarding',
      render: (regarding) => <span>{truncateFormatter(humanFormatter(regarding), 20)}</span>
    },
    {
      dataIndex: 'reason',
      title: 'Reason',
      render: (reason) => <span>{truncateFormatter(humanFormatter(reason), 20)}</span>
    },
    {
      dataIndex: 'createdAt',
      title: 'Created At',
      sorter: true,
      render: dateFormatter
    },
    {
      dataIndex: 'allCompleted',
      title: 'All Complete',
      sorter: true,
      render: (_, refund) => {
        const reversalsCompleted = refund.reversals.every((reversal) => reversal.completedAt)
        const allCompleted = refund.refund.completedAt && reversalsCompleted
        return <>{checkFormatter(allCompleted)}</>
      }
    },
    {
      render: (_, refund) => <RefundActionsDropdown refund={refund} />
    }
  ]
})

export default refundListProps
