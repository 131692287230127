import React from 'react'
import { Avatar } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import gravatar from 'gravatar'
import UserDrawer from './UserDrawer'
import DrawerLink from '../shared/DrawerLink/DrawerLink'

const UserLink = ({ disabled = false, user, children }) => {
  if (user == null) return <></>
  const profileUrl = gravatar.url(user.email, { s: '100', d: 'mp' })
  const name = <span>{user.fullName || `${user.firstName} ${user.lastName}`}</span>
  const avatar = <Avatar className="mr-3" src={profileUrl} size={36} icon={<UserOutlined />} />
  return (
    <DrawerLink drawerElement={UserDrawer} childProps={{ id: user.id }} disabled={disabled}>
      {children || (
        <>
          {avatar}
          {name}
        </>
      )}
    </DrawerLink>
  )
}

export default UserLink
