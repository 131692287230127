import React from 'react'

// GraphQL
import { UPDATE_GIFT_CARD } from '../../graphql/mutations'

// Plugins
import dayjs from 'dayjs'
import { Form, Input, Button, DatePicker, message } from 'antd'

// Components
import Drawer from '../shared/Drawer'

// Helpers / Hooks
import { useMutation } from '@apollo/react-hooks'
import { useGraphqlError, useErrors } from '../../utilities/hooks'
import { mapCentsToCurrency, mapCurrencyToCents } from '../../utilities/formatters'

// Constants
const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}
const tailLayout = {
  wrapperCol: { offset: 6, span: 18 }
}

const currencyFields = ['remainingAmountCents', 'amountCents']
const validAttributes = ['remainingAmountCents', 'subjectDetails', 'expiration']

const CreditEditDrawer = (props) => {
  const { giftCard, onClose } = props

  const initialValues = {
    ...mapCentsToCurrency(giftCard, currencyFields),
    expiration: giftCard.expiration ? dayjs(giftCard.expiration, 'YYYY-MM-DD') : null
  }

  const [updateGiftCard, { data, loading, error }] = useMutation(UPDATE_GIFT_CARD)

  useErrors(data?.updateGiftCard?.errors)
  useGraphqlError(error)

  const onFinish = async (values) => {
    const mapped = mapCurrencyToCents(values, currencyFields)
    const filtered = validAttributes.reduce((obj, key) => ({ ...obj, [key]: mapped[key] }), {})
    const params = { variables: { input: { id: giftCard.id, attributes: filtered } } }
    const response = await updateGiftCard(params)

    if (response?.data?.updateGiftCard?.errors?.length === 0) {
      message.success('Credit updated.')
      onClose()
    }
  }

  const onFinishFailed = (errorInfo) => console.log('Failed:', errorInfo)

  return (
    <Drawer {...props} width={480}>
      <h3>Credit Edit: {giftCard.num}</h3>
      <Form className="p-8" {...layout} name="basic" initialValues={initialValues} onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <Form.Item label="Number" name="num">
          <Input disabled />
        </Form.Item>

        <Form.Item label="Email" name="email">
          <Input disabled />
        </Form.Item>

        <Form.Item label="Phone" name="phone">
          <Input disabled />
        </Form.Item>

        <Form.Item label="Amount" name="amountCents">
          <Input prefix="$" suffix="USD" disabled />
        </Form.Item>

        <Form.Item label="Remaining" name="remainingAmountCents">
          <Input prefix="$" suffix="USD" />
        </Form.Item>

        <Form.Item label="Subject" name="subjectDetails">
          <Input />
        </Form.Item>

        <Form.Item label="Expiration" name="expiration">
          <DatePicker allowClear={false} />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" loading={loading}>
            Save
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  )
}

export default CreditEditDrawer
