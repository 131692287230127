import React, { useCallback } from 'react'

// GraphQL
import { UPDATE_REPRINT_REQUEST, UPDATE_SMS_CAMPAIGN_REQUEST } from '../../graphql/mutations'

// Plugins
import PubSub from 'pubsub-js'
import { DownOutlined } from '@ant-design/icons'
import { Dropdown, Tooltip, message } from 'antd'

// Components
import ButtonLink from '../shared/ButtonLink/ButtonLink'

// Helpers / Hooks
import { useMutation } from '@apollo/react-hooks'
import { hasPermission } from '../../utilities/permissions'
import { useGraphqlError, useErrors } from '../../utilities/hooks'

const RequestBulkActionsDropdown = ({ selectedRowKeys, clearSelection, requestType }) => {
  const [updateSmsCampaignRequest, { data: updateSmsData, loading: updateSmsLoading, error: updateSmsCampaignError }] = useMutation(
    UPDATE_SMS_CAMPAIGN_REQUEST,
    { errorPolicy: 'all' }
  )
  useErrors(updateSmsData?.updateSmsCampaignRequest?.errors)
  useGraphqlError(updateSmsCampaignError)

  const [updateReprintRequest, { data: updateReprintData, loading: updateReprintLoading, error: updateReprintError }] = useMutation(UPDATE_REPRINT_REQUEST, {
    errorPolicy: 'all'
  })
  useErrors(updateReprintData?.updateReprintRequest?.errors)
  useGraphqlError(updateReprintError)

  const hasAccess = hasPermission('admin_support_requests')
  const loading = updateSmsLoading || updateReprintLoading

  const handleBulkRequest = useCallback(
    async (action) => {
      const params = {
        variables: {
          input: {
            ids: selectedRowKeys,
            status: null
          }
        }
      }

      if (action === 'approve') {
        params.variables.input.status = 'approved'
      }
      if (action === 'approveManual') {
        params.variables.input.status = 'approved_manual'
      }
      if (action === 'complete') {
        params.variables.input.status = 'complete'
        params.variables.input.comment = 'Manual Complete'
      }

      if (requestType === 'smsCampaign') {
        const response = await updateSmsCampaignRequest(params, {})
        const errors = response.data?.updateSmsCampaignRequest?.errors || response?.errors

        if (errors && errors.length > 0) return message.error(errors[0].message)
      } else if (requestType === 'reprint') {
        const response = await updateReprintRequest(params, {})
        const errors = response.data?.updateReprintRequests?.errors || response?.errors
        if (errors && errors.length > 0) return message.error(errors[0].message)
      } else {
        return message.error('Invalid request type.')
      }

      message.success(`${selectedRowKeys.length} support requests updated.`)
      PubSub.publish('PAGED_LIST_REFRESH')
      clearSelection()
    },
    [requestType, selectedRowKeys, clearSelection, updateReprintRequest, updateSmsCampaignRequest]
  )

  const items = [
    {
      key: '0',
      label: (
        <Tooltip title={hasAccess ? null : 'You must request permission to be able to manage support entities.'}>
          <ButtonLink name="bulk approve" disabled={!hasAccess} onClick={() => (hasAccess ? handleBulkRequest('approve') : null)}>
            Approve
          </ButtonLink>
        </Tooltip>
      )
    },
    {
      key: '1',
      label: (
        <>
          {requestType === 'reprint' ? (
            <Tooltip title={hasAccess ? null : 'You must request permission to be able to manage support entities.'}>
              <ButtonLink name="bulk approve manual" disabled={!hasAccess} onClick={() => (hasAccess ? handleBulkRequest('approveManual') : null)}>
                Approve - Manual
              </ButtonLink>
            </Tooltip>
          ) : null}
        </>
      )
    },
    {
      key: '2',
      label: (
        <Tooltip title={hasAccess ? null : 'You must request permission to be able to manage support entities.'}>
          <ButtonLink name="bulk complete" disabled={!hasAccess} onClick={() => (hasAccess ? handleBulkRequest('complete') : null)}>
            Complete
          </ButtonLink>
        </Tooltip>
      )
    }
  ]

  return (
    <>
      {selectedRowKeys && selectedRowKeys.length > 1 && (
        <Dropdown menu={{ items }} trigger={['click']} disabled={loading}>
          <span className="ant-dropdown-link ml-4">
            Bulk Actions &nbsp;
            <DownOutlined />
          </span>
        </Dropdown>
      )}
    </>
  )
}

export default RequestBulkActionsDropdown
