import React from 'react'
import { HomeOutlined, ProfileOutlined } from '@ant-design/icons'
import BaseLayout from '../layout/BaseLayout'
import Page from '../layout/Page'
import PagedList from '../shared/PagedList'
import studioListProps from '../studios/studioListProps'
import { buildBreadCrumbs } from '../../utilities/breadcrumbs'

const Studios = () => {
  return (
    <BaseLayout section="studios" breadcrumb={breadcrumb}>
      <Page>
        <PagedList {...studioListProps} />
      </Page>
    </BaseLayout>
  )
}

const breadcrumb = buildBreadCrumbs([
  {
    href: '/',
    icon: <HomeOutlined />
  },
  {
    icon: <ProfileOutlined />,
    name: 'Profiles'
  },
  {
    href: '/studios',
    name: 'Studios'
  }
])

export default Studios
