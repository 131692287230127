import React from 'react'
import { Form, Input, Button, Select, message } from 'antd'
import { useMutation } from '@apollo/react-hooks'
import { useGraphqlError, useErrors } from '../../utilities/hooks'
import Drawer from '../shared/Drawer'
import { SEND_SHIP_ORDER } from '../../graphql/mutations'
import PubSub from 'pubsub-js'

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}
const tailLayout = {
  wrapperCol: { offset: 6, span: 18 }
}

const carriers = ['DHL', 'FedEx', 'UPS', 'USPS']

const generateID = () => {
  return Math.random().toString().slice(2, 18)
}

const OrderShipDrawer = (props) => {
  const { order, onClose } = props
  const [sendShipOrder, { data, loading, error }] = useMutation(SEND_SHIP_ORDER)
  useErrors(data?.sendShipOrder?.errors)
  useGraphqlError(error)

  const onFinish = async (values) => {
    const { tracking_number, carrier } = values
    const params = { variables: { input: { id: order.id, trackingNumber: tracking_number, carrier: carrier } } }
    const response = await sendShipOrder(params)
    if (response?.data?.sendShipOrder?.errors?.length === 0) {
      message.success('Shipped.')
      PubSub.publish('PAGED_LIST_REFRESH')
      onClose()
    }
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  const initialValues = {
    tracking_number: generateID(),
    carrier: 'FedEx'
  }

  return (
    <Drawer {...props} width={600}>
      <h3>Ship Order: {order.num}</h3>
      <Form {...layout} className="p-5" name="basic" initialValues={initialValues} onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <Form.Item label="Tracking Number" name="tracking_number" rules={[{ required: true, message: 'Tracking Number' }]}>
          <Input />
        </Form.Item>

        <Form.Item label="Carrier " name={'carrier'} rules={[{ required: true, message: 'Carrier' }]}>
          <Select>
            {carriers.map((carrier, index) => {
              return (
                <Select.Option key={index} value={carrier}>
                  {carrier}
                </Select.Option>
              )
            })}
          </Select>
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" loading={loading}>
            Save
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  )
}

export default OrderShipDrawer
