/**
 * Builds a list configuration with default options
 * @param {string} dataKey The key for the query. E.g. jobs, customers, refundGroups
 * @param {object} query The graphql query to fetch the list with
 * @param {object} options Any options and columns to override
 */
const listProps = (dataKey, query, options) => {
  return {
    ...{
      dataKey: dataKey,
      query: query,
      search: true,
      controls: options.controls,
      searchPlaceholder: options.searchPlaceholder || `Search ${dataKey}...`,
      startParams: {
        order: 'created_at',
        dir: 'desc'
      }
    },
    ...options
  }
}

export default listProps
