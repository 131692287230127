import React from 'react'

// Plugins
import dayjs from 'dayjs'
import { Tooltip } from 'antd'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { LinkOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons'

// Components
import Copy from '../components/shared/Copy'
import { timeZones } from '../config/TimeZones'

// Helpers / Hooks
import truncate from 'truncate'
import humanizeString from 'humanize-string'
import { FormattedNumber } from 'react-intl'

// Constants

export const boolFormatter = (value) => {
  return value ? 'Yes' : 'No'
}

dayjs.extend(utc)
dayjs.extend(timezone)

export const dateFormatter = (cell, dateOnly, inUtc = false, timeZoneName = 'Eastern Time (US & Canada)') => {
  if (cell == null) return <></>
  const timeZone = timeZones[timeZoneName] || 'America/New_York'
  const tzTime = dayjs(cell).tz(timeZone)
  const utcTime = dayjs(cell).tz('utc')
  const format = dateOnly === true ? 'MMM D YYYY' : 'MMM D YYYY, h:mm A'
  const utcDateTime = utcTime.format(format)
  const inTz = tzTime.format(format)
  const toolTipTitle = (
    <>
      <div>{`UTC: ${utcDateTime}`}</div>
      <div>{`RAW: ${dayjs(cell)}`}</div>
    </>
  )
  return (
    <Tooltip title={toolTipTitle}>
      <span className="whitespace-nowrap">{inUtc ? utcDateTime : inTz}</span>{' '}
    </Tooltip>
  )
}

export const formatTimestamp = (value, dateOnly) => {
  const est = dayjs(value).tz('America/New_York')
  const format = dateOnly === true ? 'MMM D YYYY' : 'MMM D YYYY, h:mm A'
  const inEst = est.format(format)

  return inEst
}

export const linkFormatter = (cell, fallback) => {
  if (cell == null) return fallback || <></>
  return (
    <a href={cell} target="_blank" rel="noopener noreferrer">
      {cell}
    </a>
  )
}

export const jsonFormatter = (value, parse = true) => {
  let json = value

  if (!json) return ''
  if (parse) json = JSON.parse(json)

  return (
    <pre>
      <code>{JSON.stringify(json, null, 2)}</code>
    </pre>
  )
}

export const idFormatter = (id) => {
  return <span>{id.split('-')[0]}</span>
}

export const humanFormatter = (value) => {
  return value && humanizeString(value)
}

export const currencyFormatter = (value) => {
  if (value == null) return <span>None</span>
  // eslint-disable-next-line
  return <FormattedNumber style="currency" value={value / 100.0} currency={'USD'} />
}

export const checkFormatter = (isChecked) => {
  if (isChecked) return <CheckOutlined className="text-success-500" />

  return <CloseOutlined className="text-error-500" />
}

export const truncateFormatter = (text, maxLength) => {
  if (text == null) return <></>
  if (text.length <= maxLength) return text
  return <Tooltip title={text}>{truncate(text, maxLength || 20)}</Tooltip>
}

export const phoneFormatter = (phone) => {
  return (
    <Copy text={phone}>
      <a href={`tel:${phone}`}>{phone}</a>
    </Copy>
  )
}

export const addressFormatter = (address, recipient = true) => {
  if (address == null) return <></>
  let parts = [`${address.line1} ${address.line2 || ''}`, `${address.city}`, `${address.state} ${address.zip}`]
  if (recipient) parts.unshift(`${address.recipient || ''}`)
  return parts.join(', ')
}

export const emailFormatter = (email) => {
  return (
    <Copy text={email}>
      <a href={`mailto:${email}`}>{email}</a>
    </Copy>
  )
}
export const formattedError = ({ graphQLErrors, networkError }) => {
  if (graphQLErrors.length === 0) {
    return networkError?.result?.error_localized || networkError?.result?.error
  }
  return graphQLErrors
    .map((graphqlError) => {
      return graphqlError.message
    })
    .join('\n')
}

export const studioHref = (path) => {
  return `${import.meta.env.VITE_APP_STUDIO_URL}${path}`
}

/**
 * Provides a link to studio.photoday.io site
 * @param {*} path
 */
export const studioLinkFormatter = (path) => {
  return (
    <a href={studioHref(path)} target="_blank" rel="noopener noreferrer">
      <LinkOutlined />
    </a>
  )
}

export const HTMLError = ({ error }) => (
  <div>
    {error.graphQLErrors.map(({ message }) => (
      <p key="error-key">{message}</p>
    ))}
  </div>
)

export const mapCurrencyToCents = (object, fields) => {
  const newObject = fields.reduce((current, item) => {
    current[item] = object[item] * 100.0
    return current
  }, {})
  return { ...object, ...newObject }
}

export const mapCentsToCurrency = (object, fields) => {
  const newObject = fields.reduce((current, item) => {
    current[item] = (object[item] / 100.0).toFixed(2)
    return current
  }, {})
  return { ...object, ...newObject }
}

export const stripeTransferLink = (transferId) => {
  if (transferId == null || transferId === 'NONE') {
    return 'None'
  }
  const href = `https://dashboard.stripe.com/connect/transfers/${transferId}`
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {transferId}
    </a>
  )
}

export const jobListFormatter = (jobs) => {
  if (jobs.length > 1) {
    return `${jobs.length} Jobs`
  } else {
    const job = jobs[0].job
    return job ? job.name : ''
  }
}
