import { Card, Col, Row } from 'antd'
import React from 'react'
import notFound from '../../assets/images/not_found.png'
import Page from '../layout/Page'

const NotFound = () => {
  return (
    <Page requiresAuth={false}>
      <Row type="flex" justify="center" className="pt-20">
        <Col span={8}>
          <Card className="text-center" span={8}>
            <img src={notFound} alt="Not found" width="100%" />
          </Card>
        </Col>
      </Row>
    </Page>
  )
}

export default NotFound
