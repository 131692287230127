import React, { useCallback } from 'react'

// GraphQL
import { UPDATE_REPRINT_REQUEST, UPDATE_SMS_CAMPAIGN_REQUEST } from '../../graphql/mutations'

// Plugins
import PubSub from 'pubsub-js'
import { DownOutlined } from '@ant-design/icons'
import { Dropdown, Tooltip, message } from 'antd'

// Components
import DenyDrawer from './DenyDrawer'
import DrawerLink from '../shared/DrawerLink/DrawerLink'
import ButtonLink from '../shared/ButtonLink/ButtonLink'
import ApproveReprintDrawer from './ApproveReprintDrawer/ApproveReprintDrawer'

// Helpers / Hooks
import { useMutation } from '@apollo/react-hooks'
import { hasPermission } from '../../utilities/permissions'
import { useGraphqlError, useErrors } from '../../utilities/hooks'

const RequestActionsDropdown = ({ request, requestType }) => {
  const [updateSmsCampaignRequest, { data: updateSmsData, loading: updateSmsLoading, error: updateSmsCampaignError }] = useMutation(
    UPDATE_SMS_CAMPAIGN_REQUEST,
    { errorPolicy: 'all' }
  )
  useErrors(updateSmsData?.updateSmsCampaignRequest?.errors)
  useGraphqlError(updateSmsCampaignError)

  const [updateReprintRequest, { data: updateReprintData, loading: updateReprintLoading, error: updateReprintError }] = useMutation(UPDATE_REPRINT_REQUEST, {
    errorPolicy: 'all'
  })
  useErrors(updateReprintData?.updateReprintRequest?.errors)
  useGraphqlError(updateReprintError)

  const hasAccess = hasPermission('admin_support_requests')
  const loading = updateSmsLoading || updateReprintLoading
  const showApprove = request.status !== 'approved' && request.status !== 'complete' && request.status !== 'in_progress'
  const showApproveManual = showApprove && request.status !== 'approved_manual' && request.requestType === 'reprint'
  const showComplete = request.status !== 'complete' && request.status !== 'denied' && request.requestType === 'reprint'
  const showDeny = request.status !== 'denied' && request.status !== 'complete'

  const handleUpdateRequest = useCallback(
    async (action) => {
      const params = {
        variables: {
          input: {
            id: request.id,
            status: null
          }
        }
      }

      // Set the params based on the action
      if (action === 'approve') {
        params.variables.input.status = 'approved'
      }
      if (action === 'approveManual') {
        params.variables.input.status = 'approved_manual'
      }
      if (action === 'complete') {
        params.variables.input.status = 'complete'
        params.variables.input.comment = 'Manual Complete'
      }
      if (requestType === 'reprint') {
        params.variables.input.shippingRateId = request.orderReprint?.shippingRateId ? request.orderReprint?.shippingRateId : null
      }

      // Submit the request
      if (requestType === 'smsCampaign') {
        const response = await updateSmsCampaignRequest(params, {})
        const errors = response.data?.updateSmsCampaignRequest?.errors || response?.errors

        if (errors && errors.length > 0) return message.error(errors[0].message)
      } else if (requestType === 'reprint') {
        const response = await updateReprintRequest(params, {})
        const errors = response.data?.updateReprintRequests?.errors || response?.errors
        if (errors && errors.length > 0) return message.error(errors[0].message)
      } else {
        return message.error('Invalid request type.')
      }

      message.success('Support Request updated.')
      PubSub.publish('PAGED_LIST_REFRESH')
    },
    [request, updateReprintRequest, updateSmsCampaignRequest, requestType]
  )

  const items = [
    {
      key: '0',
      label: (
        <>
          {showApprove && hasAccess ? (
            <>
              {request.requestType === 'reprint' ? (
                <DrawerLink drawerElement={ApproveReprintDrawer} childProps={{ request: request }} disabled={!hasAccess}>
                  {hasAccess && <span>Approve</span>}
                </DrawerLink>
              ) : (
                <ButtonLink name="sms-approve" onClick={() => handleUpdateRequest('approve')}>
                  Approve
                </ButtonLink>
              )}
            </>
          ) : null}
        </>
      )
    },
    {
      key: '1',
      label: (
        <>
          {showApproveManual ? (
            <Tooltip title={hasAccess ? null : 'You must request permission to be able to manage support entities.'}>
              <ButtonLink name="approve manual" disabled={!hasAccess} onClick={() => (hasAccess ? handleUpdateRequest('approveManual') : null)}>
                Approve - Manual
              </ButtonLink>
            </Tooltip>
          ) : null}
        </>
      )
    },
    {
      key: '2',
      label: (
        <>
          {showComplete ? (
            <Tooltip title={hasAccess ? null : 'You must request permission to be able to manage support entities.'}>
              <ButtonLink name="complete" disabled={!hasAccess} onClick={() => (hasAccess ? handleUpdateRequest('complete') : null)}>
                Complete
              </ButtonLink>
            </Tooltip>
          ) : null}
        </>
      )
    },
    {
      key: '3',
      label: (
        <>
          {showDeny ? (
            <DrawerLink drawerElement={DenyDrawer} childProps={{ request: request }} disabled={!hasAccess}>
              {hasAccess && <span>Deny</span>}
              {!hasAccess && (
                <Tooltip title={hasAccess ? null : 'You must request permission to be able to manage entities.'}>
                  <span className="text-neutral-500">Deny</span>
                </Tooltip>
              )}
            </DrawerLink>
          ) : null}
        </>
      )
    }
  ]

  return (
    <Dropdown menu={{ items }} trigger={['click']} disabled={loading}>
      <span className="ant-dropdown-link">
        Actions &nbsp;
        <DownOutlined />
      </span>
    </Dropdown>
  )
}

export default RequestActionsDropdown
