import React from 'react'
import DrawerLink from '../shared/DrawerLink/DrawerLink'
import CustomerDrawer from './CustomerDrawer'

const CustomerLink = ({ customer, disabled = false, children }) => {
  return (
    <DrawerLink drawerElement={CustomerDrawer} childProps={{ id: customer?.id }} disabled={disabled}>
      {children || customer?.fullName}
    </DrawerLink>
  )
}

export default CustomerLink
