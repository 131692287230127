import React from 'react'

// GraphQL
import { ORDER_RESEND_DD_RELEASE, ORDER_RESEND_RECEIPT, CANCEL_ORDER, ORDER_DOWNLOAD_FINAL_IMAGES_LIST } from '../../graphql/mutations'

// Plugins
import { saveAs } from 'file-saver'
import { useModal } from 'sunflower-antd'
import { useNavigate } from 'react-router-dom'
import { Dropdown, Tooltip, Popconfirm, message } from 'antd'

// Components
import OrderShipDrawer from './OrderShipDrawer'
import OrderEditDrawer from './OrderEditDrawer'
import SendToLabModal from './actions/SendToLabModal'
import DrawerLink from '../shared/DrawerLink/DrawerLink'
import ButtonLink from '../shared/ButtonLink/ButtonLink'
import RefundCreateDrawer from '../refunds/RefundCreateDrawer'

// Helpers / Hooks
import { DownOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/react-hooks'
import { hasPermission } from '../../utilities/permissions'
import { useErrors, useGraphqlError } from '../../utilities/hooks'

// Constants
const ORDER_CANCELED = 'canceled'

const OrderActionsDropdown = ({ order }) => {
  const navigate = useNavigate()
  const { modalProps: _sendLabProps, show: showSend, close: closeSend } = useModal({ defaultVisible: false })
  const [resendReceipt, { data }] = useMutation(ORDER_RESEND_RECEIPT, {
    onError: (error) => {
      console.log(error)
    }
  })
  const [resendDdRelease, { data: dataRelease }] = useMutation(ORDER_RESEND_DD_RELEASE, {
    onError: (error) => {
      console.log(error)
    }
  })
  const [cancelOrder, { data: dataCancelOrder }] = useMutation(CANCEL_ORDER, {
    onError: (error) => {
      message.error(`Error while cancelling bulk order: ${JSON.stringify(error)}`)
      console.log(error)
    }
  })

  // Modal property visible is deprecated use open instead
  const sendLabProps = { ..._sendLabProps, ...{ show: showSend, close: closeSend }, ...{ open: _sendLabProps.visible } }
  delete sendLabProps.visible

  const [orderDownloadFinalImagesList, { data: dataDownload, loading, error }] = useMutation(ORDER_DOWNLOAD_FINAL_IMAGES_LIST)
  useGraphqlError(error)
  useErrors(dataDownload?.orderDownloadFinalImagesList?.errors)
  useErrors(data?.orderResendReceipt?.errors)
  useErrors(dataRelease?.orderResendDdRelease?.errors)
  useErrors(dataCancelOrder?.cancelOrder?.errors)

  const viewOrder = () => {
    navigate(`/orders/${order.num}`)
  }

  const onResendReceipt = async () => {
    const response = await resendReceipt({ variables: { input: { id: order.id } } })
    if (response?.data?.orderResendReceipt?.errors?.length === 0) {
      message.success(`Receipt sent to ${order.email}.`)
    }
  }

  const onResendDdRelease = async () => {
    const response = await resendDdRelease({ variables: { input: { id: order.id } } })
    if (response?.data?.orderResendDdRelease?.errors?.length === 0) {
      message.success(`Receipt sent to ${order.email}.`)
    }
  }

  const onCancelBulkOrder = async () => {
    const response = await cancelOrder({ variables: { input: { id: order.id } } })
    if (response?.data?.cancelOrder?.errors?.length === 0) {
      message.success('Bulk order canceled.')
    }
  }

  const onDownloadPhotoList = async () => {
    const params = { variables: { input: { id: order.id } } }
    const response = await orderDownloadFinalImagesList(params)
    if (response?.data?.orderDownloadFinalImagesList?.errors?.length === 0) {
      message.success('Downloading.')
      const blob = response?.data?.orderDownloadFinalImagesList?.file?.blob
      const filename = response?.data?.orderDownloadFinalImagesList?.file?.filename
      const fileContents = window.atob(blob)
      const newBlob = new Blob([fileContents], { type: 'text/plain;charset=utf-8' })
      saveAs(newBlob, filename)
    }
  }

  const canResendOrders = hasPermission('admin_resend_orders')
  const canResendReceipts = hasPermission('admin_resend_receipts')
  const canManageRefunds = hasPermission('admin_manage_refunds')
  const canManageEntities = hasPermission('admin_manage_entities')
  const nonProductionEnv = import.meta.env.VITE_APP_DEPLOYMENT !== 'admin'
  const orderPaid = order.status === 'paid'

  const items = [
    { key: '0', label: <span onClick={viewOrder}>View</span> },
    {
      key: '1',
      label: (
        <DrawerLink drawerElement={OrderEditDrawer} childProps={{ order: order }} disabled={!canManageEntities}>
          <Tooltip title={canManageEntities ? null : 'You must request permission to be able to manage entities.'}>
            <span>Edit Order</span>
          </Tooltip>
        </DrawerLink>
      )
    },
    {
      key: '2',
      label: (
        <ButtonLink color="secondary" disabled={!canResendOrders} onClick={canResendOrders ? showSend : null}>
          <Tooltip title={canResendOrders ? null : 'You must request permission to be able to resend orders.'}>
            <span>Send to Lab</span>
          </Tooltip>
        </ButtonLink>
      )
    },
    {
      key: '3',
      label: (
        <>
          <Popconfirm
            className={`${!canResendReceipts ? 'ant__pop-confirm--disabled' : ''}`}
            title={`Send this receipt to ${order.email}?`}
            disabled={!canResendReceipts}
            onConfirm={onResendReceipt}
          >
            <Tooltip title="You must request permission to be able to resend receipts.">
              <span>Resend Receipt</span>
            </Tooltip>
          </Popconfirm>
        </>
      )
    },
    {
      key: '4',
      label: (
        <Popconfirm
          className={`${!canResendReceipts ? 'ant__pop-confirm--disabled' : ''}`}
          title={`Send this DD release to ${order.email}?`}
          disabled={!canResendReceipts}
          onConfirm={onResendDdRelease}
        >
          <Tooltip title={canResendReceipts ? null : 'You must request permission to be able to resend receipts.'}>
            <span>Resend DD Release</span>
          </Tooltip>
        </Popconfirm>
      )
    },
    {
      key: '5',
      label: (
        <DrawerLink drawerElement={RefundCreateDrawer} childProps={{ order: order, payoutId: order.payoutId }} disabled={!canManageRefunds}>
          <Tooltip title={canManageRefunds ? null : 'You must request permission to be able to manage refunds.'}>
            <span>Create Refund</span>
          </Tooltip>
        </DrawerLink>
      )
    },
    {
      key: '6',
      label: (
        <>
          {order.bulkShipOrderId && order.status !== ORDER_CANCELED ? (
            <Popconfirm
              className={`${!canManageEntities ? 'ant__pop-confirm--disabled' : ''}`}
              title={`Are you sure you want to cancel the bulk order ${order.num}?`}
              disabled={!canManageEntities}
              onConfirm={onCancelBulkOrder}
            >
              <Tooltip title={canManageEntities ? null : 'You must request permission to be able to manage entities.'}>
                <span>Cancel Bulk Order</span>
              </Tooltip>
            </Popconfirm>
          ) : null}
        </>
      )
    },
    {
      key: '7',
      label: (
        <>
          {nonProductionEnv ? (
            <DrawerLink drawerElement={OrderShipDrawer} childProps={{ order: order }} disabled={!orderPaid}>
              <Tooltip title={orderPaid ? null : 'Order was shipped already.'}>
                <span>Ship</span>
              </Tooltip>
            </DrawerLink>
          ) : null}
        </>
      )
    },
    {
      key: '8',
      label: (
        <ButtonLink
          name="download-photo-list"
          color="secondary"
          disabled={!canManageEntities}
          onClick={loading || !canManageEntities ? null : onDownloadPhotoList}
        >
          <Tooltip title={canManageEntities ? null : 'You must request permission to be able to manage entities.'}>
            <span>{loading ? 'Downloading...' : 'Download Photo List'}</span>
          </Tooltip>
        </ButtonLink>
      )
    }
  ]

  return (
    <Dropdown menu={{ items }} trigger={['click']}>
      <span className="ant-dropdown-link">
        Actions &nbsp;
        <DownOutlined />
        <SendToLabModal {...sendLabProps} order={order} />
      </span>
    </Dropdown>
  )
}

export default OrderActionsDropdown
