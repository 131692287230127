/**
 * Fetches items from a data response given a data key
 * @param {string} dataKey - The key for the collection .e.g. users
 * @param {string} data - The apollo data from the response
 */
export const getItems = (dataKey, data) => {
  if (data == null) return []
  return data[dataKey]?.items || []
}

/**
 * Fetches pageInfo from a data response given a data key
 * @param {string} dataKey - The key for the collection .e.g. users
 * @param {string} data - The apollo data from the response
 */
export const getPageInfo = (dataKey, data) => {
  if (data == null) return {}
  return data[dataKey]?.pageInfo || {}
}

/**
 * Map table parameters to server format
 * Server keys are dir: asc/desc, order: :field
 * Table keys are order: ascend/descend, field: :field
 * Kind of confusing because the order actually means 'sort column'
 * @param {string} dataKey - The key for the collection .e.g. users
 * @param {string} data - The apollo data from the response
 */
export const tableMap = (params) => {
  let newParams = params
  if (newParams['field']) {
    let order = newParams['order']
    newParams['order'] = newParams['field']
    newParams['dir'] = order
    delete newParams['field']
  }
  if (newParams['dir'] === 'ascend') newParams['dir'] = 'asc'
  if (newParams['dir'] === 'descend') newParams['dir'] = 'desc'
  if (!newParams['order']) newParams['order'] = null
  if (!newParams['dir']) {
    newParams['dir'] = null
    newParams['order'] = null
  }
  return newParams
}
