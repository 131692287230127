import React from 'react'

// Plugins
import { Descriptions } from 'antd'

// Components
import Copy from '../../shared/Copy'
import RequestActionsDropdown from '../RequestActionsDropdown'
import { OrderLink, StudioLink, UserLink, JobLink } from '../../shared/Links'

// Helpers / Hooks
import { formatTimestamp, humanFormatter, dateFormatter } from '../../../utilities/formatters'

// Constants
const { Item } = Descriptions
const PERMITTED_ATTRIBUTES = ['recipient', 'line1', 'line2', 'city', 'state', 'zip', 'skipVerification']

const Request = ({ request }) => {
  const isReprint = request.requestType === 'reprint'
  const title = isReprint ? 'Reprint Request' : 'SMS Campaign'
  const isSmsCampaign = request.requestType === 'custom_promotion'
  const requestType = isReprint ? 'reprint' : isSmsCampaign ? 'smsCampaign' : null

  if (request == null || !request) return <></>

  const {
    orderReprint: requestOrderReprint,
    studioPromotion: requestStudioPromotion,
    studio: requestStudio,
    status: requestStatus,
    createdAt: requestCreateAt,
    createdAgo: requestCreatedAgo,
    completedAt: requestCompletedAt,
    completedIn: requestCompletedIn,
    user: requestUser
  } = request

  const {
    num: requestOrderReprintNum,
    address: requestOrderReprintAddress,
    partial: requestOrderReprintPartial,
    order: requestOrderReprintOrder,
    reason: requestOrderReprintReason,
    extraInfo: requestOrderReprintExtraInfo,
    shippingRate: requestOrderReprintShippingRate,
    orderReprintProducts: requestOrderReprintProducts,
    colorCorrection: requestOrderReprintColorCorrection
  } = requestOrderReprint || {}
  const {
    studioPromotionJobs: requestStudioPromotionJobs,
    promotion: requestStudioPromotionPromotion,
    startAt: studioPromotionStartAt,
    endAt: studioPromotionEndAt
  } = requestStudioPromotion || {}

  const existingShippingAddress = requestOrderReprintAddress || requestOrderReprintOrder?.address || null
  return (
    <>
      <Descriptions title={`${title} Details`} size="small" column={1} bordered extra={<RequestActionsDropdown request={request} requestType={requestType} />}>
        <Item label="ID">
          <Copy text={request.id}>{request.id}</Copy>
        </Item>
        <Item label="Request Type">{humanFormatter(request.requestType)}</Item>
        <Item label="Studio">
          <StudioLink studio={requestStudio} />
        </Item>
        <Item label="Studio Owner">
          <UserLink user={requestStudio?.owner} />
        </Item>
        <Item label="Status">{humanFormatter(requestStatus)}</Item>
        <Item label="Requested">
          {formatTimestamp(requestCreateAt)} ({requestCreatedAgo} ago)
        </Item>

        {isReprint && requestOrderReprintOrder && (
          <>
            {requestStatus === 'complete' && (
              <>
                <Item label="Completed">
                  {requestCompletedAt} (in {requestCompletedAt})
                </Item>
                <Item label="Tracking Number">{requestOrderReprint?.trackingNumber}</Item>
                <Item label="Shipping Carrier">{requestOrderReprint?.carrier}</Item>
              </>
            )}
            <Item label="Lab">{requestOrderReprintOrder.lab.name}</Item>
            <Item label="Order">
              <OrderLink order={requestOrderReprintOrder} />
            </Item>
            <Item label="Reprint Number">{requestOrderReprintNum}</Item>
            <Item label="Reprint Reason">{humanFormatter(requestOrderReprintReason)}</Item>
            <Item label="Reprint Details">{requestOrderReprintExtraInfo}</Item>
            {existingShippingAddress && (
              <Item label="Shipping Address">
                <dl className="p-4">
                  {Object.keys(existingShippingAddress)
                    .filter((attr) => PERMITTED_ATTRIBUTES.includes(attr))
                    .map((key) => {
                      const isLine2 = key === 'line2'
                      const isSkipVerification = key === 'skipVerification'
                      const label = isSkipVerification ? 'Skip Verification' : key
                      const value = isSkipVerification ? (existingShippingAddress[key] ? 'True' : 'False') : existingShippingAddress[key]
                      const style = isSkipVerification ? { display: 'flex', gap: 10 } : null

                      if (isLine2 && !value) return null

                      return <DlElement key={key} label={label} value={value} style={style} />
                    })}
                </dl>
              </Item>
            )}

            <Item label="Reprint Type">{requestOrderReprintPartial ? 'Partial' : 'Full'}</Item>
            <Item label="Reprint Products Count">{requestOrderReprintPartial && requestOrderReprintProducts ? requestOrderReprintProducts.length : 'All'}</Item>

            <Item label="Shipping">
              {requestOrderReprintShippingRate?.description} ({requestOrderReprintShippingRate?.rate})
            </Item>

            <Item label="Assignee">{requestUser?.fullName}</Item>

            {requestOrderReprintOrder.lab.key === 'whcc' && <Item label="Color Correction">{requestOrderReprintColorCorrection ? 'Yes' : 'No'}</Item>}
          </>
        )}

        {isSmsCampaign && requestStudioPromotion && (
          <>
            {requestStatus === 'complete' && (
              <Item label="Completed">
                {requestCompletedAt} (in {requestCompletedIn})
              </Item>
            )}
            <Item label="Promotion">{requestStudioPromotionPromotion?.name}</Item>
            {!!requestStudioPromotionJobs?.length && (
              <Item label="Job List">
                {requestStudioPromotionJobs?.map((spj, i, arr) => (
                  <span key={i}>
                    <JobLink job={spj.job} />
                    {arr.length - 1 !== i && <span>, </span>}
                  </span>
                ))}
              </Item>
            )}
            <Item label="First Day">{dateFormatter(studioPromotionStartAt, true, true)}</Item>
            <Item label="Last Day">{dateFormatter(studioPromotionEndAt, true, true)}</Item>
          </>
        )}
      </Descriptions>
    </>
  )
}

const DlElement = ({ label, value, style }) => (
  <span style={style ? style : { display: 'grid', gridTemplateColumns: '75px 1fr' }}>
    <dt className="capitalize">{label}:</dt>
    <dd className="mb-0">{value}</dd>
  </span>
)

export default Request
