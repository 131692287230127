import React from 'react'
import { Select } from 'antd'
import useListQuery from '../../../api/useListQuery'

const SelectControl = ({ control, onChange }) => {
  const onInputChange = (value) => {
    let params = {}
    params[control.dataField] = value
    onChange(params)
  }

  let options = []
  const query = control.optionsQuery
  const params = { ...{ page: 1, perPage: 9999, order: query.label }, ...query.params }
  const { items } = useListQuery(query.dataKey, query.query, params)

  items.forEach((item) => {
    const option = { label: item[query.label], value: item[query.value] }
    options.push(option)
  })

  return (
    <Select
      className="m-0 mr-2 mb-2"
      options={options}
      placeholder={control.placeholder}
      defaultValue={control.defaultValue}
      optionLabelProp="label"
      style={{ width: control.width || 200 }}
      onChange={onInputChange}
      onClear={onInputChange}
      filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      allowClear
      showSearch
    />
  )
}

export default SelectControl
