import { Link } from 'react-router-dom'
import { Menu } from 'antd'
import { DashboardOutlined, BuildOutlined, ShoppingOutlined, ProfileOutlined, CustomerServiceOutlined } from '@ant-design/icons'
import React from 'react'

const { SubMenu } = Menu

const Navigation = ({ section, selectSection }) => {
  const defaultOpenKeys = selectSection && ['store', 'payments', 'tools', 'profiles', 'support']
  const selectedKeys = selectSection && section
  const defaultSelectedKeys = selectSection && ['dashboard']

  const items = [
    {
      key: 'dashboard',
      icon: <DashboardOutlined />,
      label: (
        <span>
          {selectSection ? (
            <Link className="text-white" to="/">
              Dashboard
            </Link>
          ) : (
            <span>Dashboard</span>
          )}
        </span>
      ),
      ...(!selectSection && { children: [{ key: 'home', label: <Link to="/">Home</Link> }] })
    },
    {
      key: 'store',
      icon: <BuildOutlined />,
      label: <span>Store</span>,
      children: [
        {
          key: 'jobs',
          label: <Link to="/jobs">Jobs</Link>
        }
      ]
    },
    {
      key: 'payments',
      icon: <ShoppingOutlined />,
      label: <span>Payments</span>,
      children: [
        { key: 'orders', label: <Link to="/orders">Orders</Link> },
        { key: 'credits', label: <Link to="/credits">Credits</Link> },
        { key: 'refunds', label: <Link to="/refunds">Refunds</Link> }
      ]
    },
    { key: 'tools', label: 'Tools', icon: <ShoppingOutlined />, children: [{ key: 'policies', label: <Link to="/policies">Policies</Link> }] },
    {
      key: 'support',
      icon: <CustomerServiceOutlined />,
      label: 'Support',
      children: [
        { key: 'reprints', label: <Link to="/reprints">Reprints</Link> },
        { key: 'sms-campaigns', label: <Link to="/sms-campaigns">SMS Campaigns</Link> }
      ]
    },
    {
      key: 'profiles',
      icon: <ProfileOutlined />,
      label: 'Profiles',
      children: [
        { key: 'studios', label: <Link to="/studios">Studios</Link> },
        { key: 'users', label: <Link to="/users">Users</Link> },
        { key: 'customers', label: <Link to="/customers">Customers</Link> },
        { key: 'labs', label: <Link to="/labs">Labs</Link> }
      ]
    }
  ]

  return (
    <Menu
      theme="dark"
      mode="inline"
      defaultSelectedKeys={defaultSelectedKeys}
      selectedKeys={selectedKeys}
      defaultOpenKeys={defaultOpenKeys}
      items={items}
    ></Menu>
  )
}

export default Navigation
