import React from 'react'
import DrawerLink from '../shared/DrawerLink/DrawerLink'
import CreditDrawer from './CreditDrawer'

const CreditLink = ({ children, credit, disabled = false }) => {
  return (
    <DrawerLink drawerElement={CreditDrawer} childProps={{ id: credit.id }} disabled={disabled}>
      {children || credit.num}
    </DrawerLink>
  )
}

export default CreditLink
