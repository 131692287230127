import React from 'react'
import { HomeOutlined, ShoppingOutlined } from '@ant-design/icons'
import BaseLayout from '../layout/BaseLayout'
import Page from '../layout/Page'
import PagedList from '../shared/PagedList'
import orderListProps from '../orders/orderListProps'
import { buildBreadCrumbs } from '../../utilities/breadcrumbs'

const Orders = () => {
  return (
    <BaseLayout section="orders" breadcrumb={breadcrumb}>
      <Page>
        <PagedList {...orderListProps} />
      </Page>
    </BaseLayout>
  )
}

const breadcrumb = buildBreadCrumbs([
  {
    href: '/',
    icon: <HomeOutlined />
  },
  {
    icon: <ShoppingOutlined />,
    name: 'Payments'
  },
  {
    href: '/orders',
    name: 'Orders'
  }
])

export default Orders
