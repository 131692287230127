import React from 'react'
import { Descriptions } from 'antd'
import { dateFormatter } from '../../utilities/formatters'
import Drawer from '../shared/Drawer'
import Copy from '../shared/Copy'
import useObjectQuery from '../../api/useObjectQuery'
import { POLICY_QUERY } from '../../graphql/queries'

const { Item } = Descriptions

const PolicyDrawer = (props) => {
  const { id } = props
  const { object: companyPolicy } = useObjectQuery('companyPolicy', POLICY_QUERY, id)
  if (companyPolicy == null) return <></>
  return (
    <Drawer {...props} width={600}>
      <Descriptions column={1} title={`Company Policy:`} bordered>
        <Item label="ID">
          <Copy text={companyPolicy.id}>{companyPolicy.id}</Copy>
        </Item>
        <Item label="Name">{companyPolicy.name}</Item>
        <Item label="Description">{companyPolicy.description}</Item>
        <Item label="Key">{companyPolicy.key}</Item>
        <Item label="URL">{companyPolicy.url}</Item>
        <Item label="User Changed At">{dateFormatter(companyPolicy.userChangedAt)}</Item>
        <Item label="Customer Changed At">{dateFormatter(companyPolicy.customerChangedAt)}</Item>
      </Descriptions>
    </Drawer>
  )
}

export default PolicyDrawer
