import { useQuery } from '@apollo/react-hooks'

const useImperativeQuery = (query, options = {}) => {
  const { refetch } = useQuery(query, { skip: true, ...options }) // <- will these options persist

  return (variables) => {
    return refetch(variables)
  }
}
export default useImperativeQuery
