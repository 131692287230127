import React from 'react'

// Plugins
import { Dropdown, Tooltip } from 'antd'
import { DownOutlined } from '@ant-design/icons'

// Components
import CreditLink from './CreditLink'
import DrawerLink from '../shared/DrawerLink/DrawerLink'
import RefundCreateDrawer from '../refunds/RefundCreateDrawer'

// Helpers / Hooks
import { hasPermission } from '../../utilities/permissions'

const CreditActionsDropdown = ({ credit }) => {
  const canManageRefunds = hasPermission('admin_manage_refunds')

  const items = [
    { key: '0', label: <CreditLink credit={credit}>View</CreditLink> },
    {
      key: '1',
      label: (
        <DrawerLink drawerElement={RefundCreateDrawer} childProps={{ credit: credit, payoutId: credit.payoutId }} disabled={!canManageRefunds}>
          <Tooltip title={canManageRefunds ? null : 'You must request permission to be able to manage refunds.'}>Create Refund</Tooltip>
        </DrawerLink>
      )
    }
  ]
  return (
    <Dropdown menu={{ items }} trigger={['click']}>
      <span className="ant-dropdown-link">
        Actions &nbsp;
        <DownOutlined />
      </span>
    </Dropdown>
  )
}

export default CreditActionsDropdown
