import React, { useState } from 'react'

// Components
import { WrapClickable } from '../../../utilities/general'

// Styles
import './DrawerLink.css'

/**
 * Represents a clickable link that becomes a drawer when clicked
 * @param {object} childProps The props to pass to the antd drawer
 * @param {boolean} clickable Whether or not to open the drawer when clicked
 * @param {object} children React children to show instead of the default
 */
const DrawerLink = (props) => {
  const { children, drawerElement, childProps, className, disabled = false } = props
  const [drawerVisible, setDrawerVisible] = useState(false)
  if (childProps == null) return <></>
  if (Object.values(childProps).every((value) => value == null)) return <></>
  return (
    <div className={`drawer-link ${className ? className : ''}`} disabled={disabled}>
      <WrapClickable
        container={'span'}
        onClick={() => {
          setDrawerVisible(true)
        }}
        disabled={disabled}
      >
        {children}
      </WrapClickable>
      {drawerVisible &&
        React.createElement(drawerElement, {
          ...childProps,
          ...{
            visible: drawerVisible,
            onClose: () => {
              setDrawerVisible(false)
            }
          }
        })}
    </div>
  )
}

export default DrawerLink
