import React from 'react'
import { Link } from 'react-router-dom'
import { Card, Col, Row } from 'antd'
import { DashboardOutlined } from '@ant-design/icons'
import BaseLayout from '../layout/BaseLayout'
import Page from '../layout/Page'
import Copy from '../shared/Copy'
import PagedList from '../shared/PagedList'
import { StudioLink, CreditLink, OrderLink } from '../shared/Links'
import { buildBreadCrumbs } from '../../utilities/breadcrumbs'
import { DASHBOARD_GIFT_CARD_LIST_QUERY, DASHBOARD_ORDERS_LIST_QUERY } from '../../graphql/queries'

const creditListProps = {
  dataKey: 'giftCards',
  query: DASHBOARD_GIFT_CARD_LIST_QUERY,
  startParams: {
    perPage: 6,
    page: 1,
    order: 'created_at',
    dir: 'desc'
  },
  pagination: false,
  showHeader: false,
  search: false,
  columns: [
    {
      dataIndex: 'num',
      title: 'Number',
      sorter: true,
      render: (num, credit) => (
        <Copy text={num}>
          <CreditLink credit={credit} />
        </Copy>
      )
    },
    {
      dataIndex: 'studio.name',
      title: 'Studio',
      sorter: true,
      render: (name, giftCard) => <StudioLink studio={giftCard.studio}>{giftCard.studio?.name}</StudioLink>
    },
    {
      dataIndex: 'amount',
      title: 'Amount',
      sorter: false
    }
  ]
}

const orderListProps = {
  dataKey: 'orders',
  query: DASHBOARD_ORDERS_LIST_QUERY,
  startParams: {
    perPage: 6,
    page: 1,
    order: 'submitted_at',
    dir: 'desc'
  },
  pagination: false,
  showHeader: false,
  search: false,
  columns: [
    {
      dataIndex: 'num',
      title: 'Number',
      sorter: true,
      render: (num, order) => (
        <Copy text={num}>
          <OrderLink order={order} />
        </Copy>
      )
    },
    {
      dataIndex: 'studio.name',
      title: 'Studio',
      sorter: true,
      render: (name, order) => <StudioLink studio={order.studio}>{order?.studio.name}</StudioLink>
    },
    {
      dataIndex: 'total',
      title: 'Total',
      sorter: false
    }
  ]
}

const Dashboard = () => (
  <BaseLayout breadcrumb={breadcrumb}>
    <Page>
      <Row className="flex w-full" gutter={24}>
        <Col className="w-auto grow">
          <Card className="w-full" hoverable title="Recent Orders" extra={<Link to="/orders">View All</Link>}>
            <PagedList {...orderListProps} />
          </Card>
        </Col>
        <Col className="w-auto grow">
          <Card className="w-full" hoverable title="Recent Credit Purchases" extra={<Link to="/credits">View All</Link>}>
            <PagedList {...creditListProps} />
          </Card>
        </Col>
      </Row>
    </Page>
  </BaseLayout>
)

const breadcrumb = buildBreadCrumbs([
  {
    href: '/',
    icon: <DashboardOutlined />,
    name: 'Dashboard'
  }
])

export default Dashboard
