import React from 'react'
import { Form, Button, message, Spin } from 'antd'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { useGraphqlError, useErrors } from '../../utilities/hooks'
import RefundForm from './RefundForm'
import Drawer from '../shared/Drawer'
import { UPDATE_REFUND_GROUP } from '../../graphql/mutations'
import { REFUNDS_MAXIMUM_QUERY } from '../../graphql/queries'

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 }
}

const RefundEditDrawer = (props) => {
  const { refund, onClose } = props
  const purchasable = refund.payout.purchasable
  const payoutId = refund.payout.id
  const { data: queryData, loading: queryLoading } = useQuery(REFUNDS_MAXIMUM_QUERY, {
    variables: { payoutId }
  })
  const [updateRefundGroup, { data, loading, error }] = useMutation(UPDATE_REFUND_GROUP)
  const [form] = Form.useForm()
  useErrors(data?.updateRefundGroup?.errors)
  useGraphqlError(error)

  if (payoutId == null) return <></>

  const onFinish = async (_values) => {
    let params = await form.validateFields()
    params = { variables: { input: { id: refund.id, attributes: params } } }
    const response = await updateRefundGroup(params)
    if (response?.data?.updateRefundGroup?.errors?.length === 0) {
      message.success('Refund updated.')
      onClose()
    }
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  const maximumsFormatted = {
    customer: ((queryData?.refundMaximums?.maximumCustomerCents || 0) / 100.0).toFixed(2),
    studio: ((queryData?.refundMaximums?.maximumStudioCents || 0) / 100.0).toFixed(2),
    lab: ((queryData?.refundMaximums?.maximumLabCents || 0) / 100.0).toFixed(2)
  }

  return (
    <Drawer {...props} width={800}>
      <h3>Refund Edit: {purchasable.num}</h3>
      {queryLoading && (
        <div className="text-center m-auto">
          <Spin className="pt-20" />
        </div>
      )}
      {!queryLoading && (
        <RefundForm
          payoutId={payoutId}
          refund={refund}
          customer={purchasable.customer}
          studio={purchasable.studio}
          lab={purchasable.lab}
          maximumsFormatted={maximumsFormatted}
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Save
            </Button>
          </Form.Item>
        </RefundForm>
      )}
    </Drawer>
  )
}

export default RefundEditDrawer
