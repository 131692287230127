import React from 'react'
import { Breadcrumb } from 'antd'

/**
 * Builds breadcrumbs based on an object
 * Example:
 * [{
 *  href: "/",
 *  icon: <HomeOutlined />
 *  name: null
 * },{
 *  href: "/studios",
 *  name: "Studios"
 * }]
 * @param {object[]} details - The bread crumb details
 * @returns {Breadcrumb} - An antd breadcrumb object
 */
export const buildBreadCrumbs = (details) => {
  const items = details.map((object) => ({
    href: object.href,
    title: (
      <>
        {object.icon && object.icon}
        {object.name && <span>{object.name}</span>}
      </>
    )
  }))

  return <Breadcrumb items={items} />
}
