import { useEffect } from 'react'
import { message } from 'antd'
import { formattedError } from './formatters'

export const useGraphqlError = (error) => {
  useEffect(() => {
    if (error != null) message.error(formattedError(error), 2)
  }, [error])
}

export const useErrors = (errors) => {
  useEffect(() => {
    if (errors?.length > 0) message.error(errors.map((e) => e.message).join('\n'), 2)
  }, [errors])
}
