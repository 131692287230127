import { message } from 'antd'
import { CopyOutlined } from '@ant-design/icons'
import React from 'react'
import './Copy.css'

const copyToClipboard = (text) => {
  if (text != null) {
    navigator.clipboard.writeText(text).then(() => {
      message.info('Copied.')
    })
  }
}

const Copy = ({ children, text }) => (
  <span className="copy">
    {children}
    {text && (
      <CopyOutlined
        className="copy-icon"
        onClick={() => {
          copyToClipboard(text)
        }}
      />
    )}
  </span>
)

export default Copy
