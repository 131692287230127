import React from 'react'

const Image = ({ isImagizer, src, alt = 'Image', size = {}, style = {}, className = '' }) => {
  let newSrc = src
  if (newSrc == null) return <></>
  if (isImagizer) {
    if (size && size.width) newSrc = `${newSrc}&w=${size.width * 2}`
    if (size && size.height) newSrc = `${newSrc}&h=${size.height * 2}`
  }
  let newStyle = { ...{}, ...style, ...size }
  newStyle = { ...newStyle, ...{ height: null, maxHeight: size.height } }
  return <img className={`${className ? className : ''}`} src={newSrc} alt={alt} style={newStyle} />
}

export default Image
