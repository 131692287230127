import React from 'react'
import { useNavigate } from 'react-router-dom'

// Plugins
import PubSub from 'pubsub-js'
import { Layout, Menu } from 'antd'
import { LogoutOutlined } from '@ant-design/icons'

// Components
import StudioDropdown from './StudioDropdown'
import { UserLink } from '../../shared/Links'

// Helpers / Hooks
import { clear, get } from '../../../utilities/storage'

// Constants
const { Header } = Layout

const HeaderComponent = ({ breadcrumb }) => {
  const navigate = useNavigate()
  const signOut = () => {
    clear()
    navigate('/sign-in')
  }
  PubSub.subscribe('SIGN_OUT', signOut)
  const studioId = get('studioId')
  const user = get('user') || {}

  const items = [
    {
      key: '0',
      label: <UserLink user={user} />,
      children: [
        {
          key: '1',
          label: (
            <div className="sign-out" onClick={signOut}>
              <LogoutOutlined />
              &nbsp; Sign out
            </div>
          )
        }
      ]
    }
  ]

  return (
    <Header className="flex justify-end w-full">
      <div className="flex items-start p-5 w-full">{breadcrumb}</div>
      <StudioDropdown displayReportingCode studioId={studioId} />
      <Menu className="header__menu" key="user" mode="horizontal" items={items} />
    </Header>
  )
}

export default HeaderComponent
