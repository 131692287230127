import { useQuery } from '@apollo/react-hooks'
import { getItems, getPageInfo } from './helpers'
import { useGraphqlError } from '../utilities/hooks'

/**
 * React hook that uses apollo query for fetching a list from our server
 * But also sanitizes it to an easy format for us
 * @param {string} dataKey - The key for the collection .e.g. users
 * @param {any} query - GraphQL query to make
 * @param {string} data - The apollo data from the response
 * @returns {object} - error, items, pageInfo, loading
 */
const useListQuery = (dataKey, query, params, options = {}) => {
  const queryParams = { ...{ pollInterval: 0 }, ...{ variables: params }, ...options }
  const { error, data, loading, refetch } = useQuery(query, queryParams)
  const items = (data && getItems(dataKey, data)) || []
  const pageInfo = data && getPageInfo(dataKey, data)
  useGraphqlError(error)
  return { error, items, pageInfo, loading, refetch }
}

export default useListQuery
