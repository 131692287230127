import React, { forwardRef } from 'react'

// Styles
import './ButtonLink.css'

/**
 * @param {React.ReactNode} children - The content to be displayed inside the button.
 * @param {"primary" | "secondary"} [color="primary"] - The color of the button.
 * @param {boolean} [disabled=false] - Whether the button is disabled.
 * @param {string} [name="button"] - The name of the button.
 * @param {string} [className] - Additional class names for the button.
 * @param {string} [type="button"] - The type of the button.
 * @param {function} [onClick] - The click handler for the button.
 * @param {React.Ref} ref - The ref to be forwarded to the button element.
 */
const ButtonLink = forwardRef(({ children, color = 'primary', disabled = false, name = 'button', className, type = 'button', onClick }, ref) => {
  return (
    <span className="p-0">
      <button
        ref={ref}
        className={`button-link ${className ? className : ''} button-link--${color}`}
        type={type}
        name={name}
        onClick={!disabled ? onClick : null}
        disabled={disabled}
      >
        {children}
      </button>
    </span>
  )
})

export default ButtonLink
