import React from 'react'
import Copy from '../shared/Copy'
import { UserLink, StudioLink } from '../shared/Links'
import { dateFormatter, idFormatter, checkFormatter, phoneFormatter, emailFormatter } from '../../utilities/formatters'
import listProps from '../../utilities/listProps'
import UserActionsDropdown from './UserActionsDropdown'
import { USERS_LIST_QUERY } from '../../graphql/queries'
import CreateUserButton from './CreateUserButton'

const userListProps = listProps('users', USERS_LIST_QUERY, {
  controls: [
    {
      controlType: 'custom',
      component: CreateUserButton
    }
  ],
  columns: [
    {
      dataIndex: 'id',
      title: 'ID',
      width: 110,
      render: (id, user) => (
        <Copy text={id}>
          <UserLink user={user}>{idFormatter(id)}</UserLink>
        </Copy>
      )
    },
    {
      dataIndex: 'fullName',
      title: 'Name',
      sorter: true,
      render: (fullName, user) => <UserLink user={user}>{fullName}</UserLink>
    },
    {
      dataIndex: 'studio.name',
      title: 'Studio',
      render: (_, user) => <StudioLink studio={user.studio} />
    },
    {
      dataIndex: 'phone',
      title: 'Phone',
      sorter: true,
      render: phoneFormatter
    },
    {
      dataIndex: 'email',
      title: 'E-mail',
      sorter: true,
      render: emailFormatter
    },
    {
      dataIndex: 'isPhotoday',
      title: 'Admin',
      render: checkFormatter
    },
    {
      dataIndex: 'intercomEnabled',
      title: 'Intercom',
      render: checkFormatter
    },
    {
      dataIndex: 'salesforceSync',
      title: 'Salesforce',
      render: checkFormatter
    },
    {
      dataIndex: 'createdAt',
      title: 'Created',
      sorter: true,
      render: dateFormatter
    },
    {
      render: (_, user) => <UserActionsDropdown user={user} />
    }
  ]
})

export default userListProps
