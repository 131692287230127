import React from 'react'
import Copy from '../shared/Copy'
import { OrderLink, StudioLink, JobLink } from '../shared/Links'
import { dateFormatter, currencyFormatter, phoneFormatter, emailFormatter } from '../../utilities/formatters'
import listProps from '../../utilities/listProps'
import OrderActionsDropdown from './OrderActionsDropdown'
import { ORDERS_LIST_QUERY } from '../../graphql/queries'

const orderListProps = listProps('orders', ORDERS_LIST_QUERY, {
  columns: [
    {
      dataIndex: 'num',
      title: 'Number',
      sorter: true,
      render: (num, order) => (
        <Copy text={num}>
          <OrderLink order={order} />
        </Copy>
      )
    },
    {
      dataIndex: 'studio.name',
      title: 'Studio',
      sorter: true,
      render: (_name, order) => <StudioLink studio={order.studio} />
    },
    {
      dataIndex: 'job.name',
      title: 'Job',
      sorter: true,
      render: (_name, order) => <JobLink job={order.job} />
    },
    {
      dataIndex: 'phone',
      title: 'Phone',
      sorter: true,
      render: phoneFormatter
    },
    {
      dataIndex: 'email',
      title: 'E-mail',
      sorter: true,
      render: emailFormatter
    },
    {
      dataIndex: 'totalCents',
      title: 'Total',
      render: currencyFormatter
    },
    {
      dataIndex: 'purchasedAt',
      title: 'Purchased At',
      render: dateFormatter
    },
    {
      render: (_, order) => <OrderActionsDropdown order={order} />
    }
  ]
})

export default orderListProps
