import { local, session } from 'store2'

const prefixKey = (key) => {
  const prefix = import.meta.env.VITE_APP_DEPLOYMENT
  return `${prefix}.${key}`
}

/**
 * Gets a configuration from local or session storage
 * @param {string} key The key for value
 * @return {string|object|array} The value for the key or null
 */
export const get = (key) => {
  key = prefixKey(key)
  return local.get(key) || session.get(key)
}

/**
 * Sets a configuration for local or session storage
 * @param {string} key The key for value
 * @param {string|object|array|null} value The value to set
 */
export const set = (key, value) => {
  key = prefixKey(key)
  const remember = get('remember')
  const target = remember ? local : session
  target.set(key, value)
}

export const clear = (key) => {
  if (!key) {
    session.clearAll()
    local.clearAll()
    return
  }
  key = prefixKey(key)
  local.remove(key)
  session.remove(key)
}
